export default {

        app_name: 'Lucky Live',
        '下拉可以刷新': '下拉可以重繪',
        '正在刷新': '正在重繪',
        '正在加載': '正在加載',
        '松开立即刷新': '鬆開立即重繪',
        '刷新完成': '重繪完成',
        '刷新失敗': '重繪失敗',
        '释放即可刷新...':'釋放即可重繪…',
        '加载中...':'加載中…',
        '最后更新': '最後更新',
        '上拉加载更多': '上拉加載更多',
        '释放立即加载': '釋放立即加載',
        '正在加载': '正在加載',
        '正在刷新': '正在重繪',
        '加载完成': '加載完成',
        '加载失败': '加載失敗',
        '已经全部加载完毕': '已經全部加載完畢',
        '暂时没有更多了':'暂时没有更多了',
    
        '拉黑': '拉黑',
        '解除拉黑': '解除拉黑',
        '已拉黑': '已拉黑',
        '已解除拉黑': '已解除拉黑',
        '立即签到': '立即簽到',
        '已连续签到': '已連續簽到',
        '连续签到领取额外奖励': '連續簽到領取額外獎勵',
        '天': '天',
        '美颜初始化异常，无法使用': '美顏初始化异常，無法使用',
        '提现账户': '提現帳戶',
        '您当前还没设定提现账户': '您當前還沒設定提現帳戶',
        '添加提现账户': '添加提現帳戶',
        '账户类型': '帳戶類型',

        '银行名称':'銀行名稱',
        '账户名称':'帳戶名稱',
        '请输入账户名称':'請輸入帳戶名稱',
        '账户号码':'帳戶號碼',
        '请输入账户号码':'請輸入帳戶號碼',
        '手机号码':'手機號碼',
        '请输入手机号码':'請輸入手機號碼',
        '验证码':'驗證碼',
        '请输入验证码':'請輸入驗證碼',
        '获取验证码':'獲取驗證碼',
        '重新发送':'重新發送',
        '确定':'確定',
        '添加银行卡': '添加銀行卡',
        '请提供正确的银行信息以避免提款失败': '請提供正確的銀行資訊以避免提款失敗',
        '最小可提现为':'最小可提現為 ',
        '最大可提现为':'最大可提現為 ',
        '最多可提现为':'最多可提現為 ',
        '钻石':'鑽石',
        '提现方式':'提現方式',
        
        '请输入持卡人姓名': '請輸入持卡人姓名',
        '请输入银行卡账号': '請輸入銀行卡帳號',
        '请输入银行名称': '請輸入銀行名稱',
        '请输入微信账号': '請輸入微信帳號',
        '请输入支付宝账号': '請輸入支付寶帳號',
        '请输入支付宝账号姓名': '請輸入支付寶帳號姓名',
        '支付宝': '支付寶',
        '微信': '微信',
        '银行卡': '銀行卡',
        '是否要删除该账户？': '是否要删除該帳戶？',
        '提现记录': '提現記錄',
        '赠送': '贈送',
        '账户余额': '帳戶餘額',
        '支付宝支付': '支付寶支付',
        '微信支付': '微信支付',
        '充值成功': '充值成功',
        '充值失败': '充值失敗',
        '未安装支付宝': '未安裝支付寶',
        '未安裝微信': '未安裝微信',
        '未安裝QQ': '未安裝QQ',
        '支付宝未配寘': '支付寶未配寘',
        '微信未配寘': '微信未配寘',
        'Paypal未配寘': 'Paypal未配寘',
    
        '复制链接': '複製連結',
        '复制成功': '複製成功',
        '编辑资料': '編輯資料',
        '头像': '頭像',
        '昵称': '昵稱',
        '修改昵称': '修改昵稱',
        '修改签名': '修改簽名',
        '签名': '簽名',
        '生日': '生日',
        '性別': '性別',
        '所在地': '所在地',
        '我的主播印象': '我的主播印象',
        '设定头像成功': '設定頭像成功',
        '留下点什么吧': '留下點什麼吧',
        '请输入昵称': '請輸入昵稱',
        '最多可输入20个字': '最多可輸入20個字',
        '昵称最多8个字': '昵稱最多8個字',
        '请输入昵称': '請輸入昵稱',
        '请输入签名': '請輸入簽名',
        '请选择正确的日期': '請選擇正確的日期',
        '字数超出限制': '字數超出限制',
        '最新': '最新',
        '关注': '關注',
        '关注': '關注',
        '已关注': '已關注',

        '你还没有关注任何人': '你還沒有關注任何人',
        '赶快去关注自己感兴趣的人吧': '趕快去關注自己感興趣的人吧',
        'TA还没有关注任何人': 'TA還沒有關注任何人',
        '粉丝': '粉絲',
        '获赞': '獲贊',
        '你还没有粉丝': '你還沒有粉絲',
        '完善个人信息会让更多的人关注到你哦': '完善個人資訊會讓更多的人關注到你哦',
        'TA还没有任何粉丝': 'TA還沒有任何粉絲',
        '忘记密码': '忘記密碼',
        '立即找回': '立即找回',
        '选择守护时长': '選擇守護時長',
        '选择特权': '選擇特權',
        '立即开通': '立即開通',
        '开通守护': '開通守護',
        '续费守护': '續費守護',
        '我的': '我的',
        '守护': '守護',
        '本周贡献': '本周貢獻',
        '成为TA的第一个守护': '成為TA的第一個守護',
        '快去为喜欢的主播开通守护吧': '快去為喜歡的主播開通守護吧',
        '您是当前主播的月守护\n守护日期截止到': '您是當前主播的月守護\n守護日期截止到',
        '您是当前主播的年守护\n守护日期截止到': '您是當前主播的年守護\n守護日期截止到',
        '年守护专属礼物': '年守護專屬禮物',
        '你还没有守护哦': '你還沒有守護哦',
        '您为当前主播的年守护\n无法开通7天/月守护': '您為當前主播的年守護\n無法開通7天/月守護',
        '您为当前主播的月守护，开通年守护将覆盖您的月守护时长，是否开通？': '您為當前主播的月守護，開通年守護將覆蓋您的月守護時長，是否開通？',
        '您将花费，为主播开通': '您將花費%1$s%2$s，為主播開通%3$s',
        '7天守护': '7天守護',
        '月守护': '月守護',
        '年守护': '年守護',
        '守护榜': '守護榜',
        '守护了主播': '%1$s守護了主播',
    
        '主页': '主頁',
        '点击查看TA的故事': '"%1$s"也在%2$s，~點擊查看TA的故事',
        '粉丝，快来围观呀': 'TA有%1$s粉絲，快來圍觀呀！',
        'Hi~我是主播，快来和我聊天吧。': 'Hi~我是主播，快來和我聊天吧。',
        '[图片]': '[圖片]',
        '[语音]': '[語音]',
        '[位置]': '[位置]',
        '[商品]': '[商品]',
        '[表情]': '[表情]',
        '消息': '消息',
        '忽略未读': '忽略未讀',
        '已忽略未读消息': '已忽略未讀消息',
        '暂无未读消息': '暫無未讀消息',
        '消息发送失败': '消息發送失敗',
        '操作频繁': '操作頻繁',
        '对方暂时拒绝接收您的消息': '對方暫時拒絕接收您的消息',
        '你还没有收到任何消息': '你還沒有收到任何消息',
        '发送消息…': '發送消息…',
        '图片': '圖片',
        '拍摄': '拍攝',
        '语音输入': '語音輸入',
        '位置': '位置',
        '选择图片': '選擇圖片',
        '未找到本地图片': '未找到本地圖片',
        '请选择图片': '請選擇圖片',
        '搜索地点': '蒐索地點',
        '未获取到位置信息': '未獲取到位置資訊',
        '地图未加载': '地圖未加載',
        '未获取到地址': '未獲取到地址',
        '获取位置失败': '獲取位置失敗',
        '点击关注，可及时看到对方动态': '點擊關注，可及時看到對方動態',
        '关注对方成功': '關注對方成功',
        '按住说话': '按住說話',
        '松开结束': '鬆開結束',
        '请说话…': '請說話…',
        '录音时间太短': '錄音時間太短',
        '手指上滑，取消发送': '手指上滑，取消發送',
        '已取消发送': '已取消發送',
        '系统消息': '系統消息',
        '暂无系统消息': '暫無系統消息',
        '私聊': '私聊',
        '主播印象': '主播印象',
        '添加印象': '添加印象',
        '最多选择三项': '最多選擇三項',
        '请选择印象': '請選擇印象',
        '未修改印象': '未修改印象',
        '請選擇你對主播的印象': '請選擇你對主播的印象',
        '你收到的主播印象': '你收到的主播印象',
        '你还没有收到印象哦': '你還沒有收到印象哦',
        '取消拍照': '取消拍照',
        '取消选择': '取消選擇',
        '取消裁剪': '取消裁剪',
        '裁剪失败': '裁剪失敗',
        '取消录制': '取消錄製',
        '您的登录状态失效，请重新登录！': '您的登入狀態失效，請重新登陸！',
        '我已阅读并同意':'我已閱讀並同意',
        '登录即表示您同意':'登入即代表您同意',
        '《隐私政策》':'《隱私政策》',
        '及':'及',
        '和':'和 ',
        '《服务协议》':'《服務協定》',
        '服务协议和隐私政策':'服務協定和隱私政策',
        '感谢您的支持，为了更好地维护您的权益，请阅读并同意':'感謝您的支持，為了更好地維護您的權益，請閱讀並同意',
        '登录':'立即登入',
        '其他登录渠道': '其他登入管道',
        'Hi~欢迎体验系统': 'Hi~歡迎體驗系統',
        '请输入您的手机号': '請輸入您的手機號',
        '请输入密码': '請輸入密碼',
        '立即登录': '立即登入',
        '请选择':'請選擇',
        '登录中': '登入中',
        '立即注册': '立即注册',
        '忘记密码': '忘記密碼',
        '请输入正确的手机号码': '請輸入正確的手機號碼',
        '正在授权登录': '正在授權登入',
        '登录成功': '登入成功',
        '当天观看有效时长已达上限':'当天观看有效时长已达上限',
        '手机号码不能为空':'手機號碼不能為空',
        '国际区号不能为空':'國際區號不能為空',
        '当前视频已达有效观看时长':'当前视频已达有效观看时长',
        "看视频30秒得现金打款":'看视频30秒得现金打款',

        '重复观看当前视频不计时':'重复观看当前视频不计时',
        '验证码不能为空':'驗證碼不能為空',
        
        '授权失败': '授權失敗',
        '授权取消': '授權取消',
        '请仔细阅读使用者协定并勾选': '請仔細閱讀使用者協定並勾選',

        '添加到桌面，快速访问':'添加到案頭，快速訪問',
        '添加':'添加',
    
        '安装教程':'安裝教程',
        '点击“分享”图标':'點擊“分享”圖標',
        '点击“添加到主屏幕”选项':'點擊“添加到主荧幕”選項',
        '在页面上查找':'在頁面上查找',
        '添加到主屏幕':'添加到主荧幕',
        '标记':'標記',
        '点击“添加”按钮':'點擊“添加”按鈕',
        '在主屏幕点击Lucky Live打开应用':'在主荧幕點擊Lucky Live打開應用',
    
        '点击“更多”图标':'點擊“更多”圖標',
        '点击“添加到桌面”选项':'點擊“添加到案頭”選項',
        '资源嗅探':'資源偵測',
        '网页翻译':'網頁翻譯',
        '添加到桌面':'添加到案頭',
        '页面查找':'頁面查找',
        '保存离线网页':'保存離線網頁',
        '点击“确定”按钮':'點擊“確定”按鈕',
        '编辑书签':'編輯書簽',

        '直播': '直播',
        '主播': '主播',
        '開始直播': '開始直播',
        '房间类型': '房間類型',
        '分享至': '分享至',
        '分享至（左右滑动更多分享）': '分享至（左右滑動更多分享）',
        '直播封面': '直播封面',
        '更换封面': '更換封面',
        '直播标题': '直播標題',
        '给直播写个标题吧': '給直播寫個標題吧',
        '频道' :'頻道',
        '选择直播频道': '選擇直播頻道',
        '注意选择适合自己的频道。 直播过程中，若运营人员发现选择的频道和直播内容不相符的情况，会调整您的直播频道。': '注意選擇適合自己的頻道。 直播過程中，若運營人員發現選擇的頻道和直播內容不相符的情况，會調整您的直播頻道。',
        '请设定房间密码': '請設定房間密碼',
        '请设定收费金额\n（收益以直播结束显示为准）': '請設定收費金額\n（收益以直播結束顯示為准）',
        '请设定房间密码': '請設定房間密碼',
        '请设定收费金额': '請設定收費金額',
        '收费金额': '收費金額',
        '选择收费': '選擇收費',
        '是否要结束直播？': '是否要結束直播？',
        '美颜': '美顏',
        '翻转': '翻轉',
        '闪光灯': '閃光燈',
        '伴奏': '伴奏',
        '分享': '分享',
        '游戏': '遊戲',
        '镜像': '鏡像',
        '您已开启镜像': '您已開啟鏡像',
        '您已关闭镜像': '您已關閉鏡像',
        '红包': '紅包',
        '连麦': '連麥',
        '断开连麦': '斷開連麥',
        '只有后置监视器才能开启闪光灯': '只有後置監視器才能開啟閃光燈',
        '打开闪光灯失败': '打開閃光燈失敗',
        '你已经被禁言': '你已經被禁言',
        '直播已结束': '直播已結束',
        '返回首页': '返回首頁',
        '直播时长': '直播時長',
        '收获': '收穫',
        '观看人数': '觀看人數',
        '关播中': '關播中',
        '和大家说些什么': '和大家說些什麼',
        '开启弹幕': '開啟彈幕',
        '请输入房间密码': '請輸入房間密碼',
        '密码错误': '密碼錯誤',
        '播放失败': '播放失敗',
        '視頻格式不支持': '視頻格式不支持',
        '视频地址包含中文，无法播放': '視頻地址包含中文，無法播放',
        '守护': '守護',
        '我点亮了': '我點亮了',
        '进入了直播间': '進入了直播間',
        '礼物': '禮物',
        '背包': '背包',
        '道具': '道具',
        '送': '送',
        '送了1个': '送了1個',
        '送给': '送給',
        '赠送': '贈送',
        '连送': '連送',
        'x': 'x',
        '连送x': '連送x',
        '靓': '靚',
        '送出': '送出',
        '收入': '收入',
        '都市未设定': '都市未設定',
        '礼物贡献榜': '禮物貢獻榜',
        '踢人': '踢人',
        '永久禁言': '永久禁言',
        '本场禁言': '本場禁言',
        '设为管理': '設為管理',
        '取消管理': '取消管理',
        '管理员清单': '管理員清單',
        '是否取消管理員身份？': '是否取消管理員身份？',
        '是否解除对该用户的禁言': '是否解除對該用戶的禁言',
        '是否解除对该用户的踢出': '是否解除對該用戶的踢出',
        '关闭直播': '關閉直播',
        '封禁直播间': '封禁直播間',
        '禁用账户': '禁用帳戶',
        '当前管理员': '當前管理員',
        '被踢出房间': '被踢出房間',
        '被永久禁言': '被永久禁言',
        '被本场禁言': '被本場禁言',
        '被设为管理员': '被設為管理員',
        '被取消管理员': '被取消管理員',
        '你已经被踢出房间': '你已經被踢出房間',
        '直播内容涉嫌违规': '直播內容涉嫌違規',
        '贡献榜': '貢獻榜',
        '资料': '資料',
        '主播离开一下，精彩不中断，不要走开哦': '主播離開一下，精彩不中斷，不要走開哦',
        '主播回来了！': '主播回來了！',
        '推流失败': '推流失敗',
        '监视器开启失败': '監視器開啟失敗',
        '麦克风开启失败': '麥克風開啟失敗',
        '暂时没有主播开播': '暫時沒有主播開播',
        '赶快开启你的直播吧': '趕快開啟你的直播吧',
        '你关注的主播没有开播': '你關注的主播沒有開播',
        '赶快去看看其他主播的直播吧': '趕快去看看其他主播的直播吧',
        '赶快去其他频道逛逛吧': '趕快去其他頻道逛逛吧',
        '暂无视频': '暫無視頻',
        '赶快发布自己是视频吧': '趕快發佈自己是視頻吧',
        '附近没有主播开播': '附近沒有主播開播',
        '去首页看看其他主播的直播吧': '去首頁看看其他主播的直播吧',
        '你还没有发布过动态': '你還沒有發佈過動態',
        '快去分享一下你的心情吧': '快去分享一下你的心情吧',
        '暂无动态': '暫無動態',
        '去推荐页看看吧': '去推薦頁看看吧',
        '他还没有发布过动态': '他還沒有發佈過動態',
        '去看看其他分类的视频吧～': '去看看其他分類的視頻吧～',
        '你最近没有开过直播': '你最近沒有開過直播',
        '赶快去开场直播体验下吧': '趕快去開場直播體驗下吧',
        'TA最近没有开过直播': 'TA最近沒有開過直播',
        'TA最近没有开过直播': 'TA最近没有开过直播',
        '关注了主播': '關注了主播',
        '确定举报？': '確定舉報？',
        '连麦互动': '連麥互動',
        '结束连麦': '結束連麥',
        '允许连麦': '允許連麥',
        '禁止连麦': '禁止連麥',
        '对方刚开播，请稍后连麦': '對方剛開播，請稍後連麥',
        '发起连麦请求': '發起連麥請求',
        '发起PK请求': '發起PK請求',
        '退出连麦': '退出連麥',
        '当前主播暂时无法接通': '當前主播暫時無法接通',
        '对方无响应': '對方無響應',
        '主播连麦中，请等会儿再试哦': '主播連麥中，請等會兒再試哦',
        '对方主播正在忙': '對方主播正在忙',
        '主播拒绝了您的连麦请求': '主播拒絕了您的連麥請求',
        '对方主播拒绝了您的连麦请求': '對方主播拒絕了您的連麥請求',
        '对方主播拒绝了您的PK请求': '對方主播拒絕了您的PK請求',
        '连麦已断开': '連麥已斷開',
        '对方主播接受了您的连麦请求，开始连麦': '對方主播接受了您的連麥請求，開始連麥',
        '对方正在忙碌': '對方正在忙碌',
        '主播接受了您的连麦请求，开始连麦': '主播接受了您的連麥請求，開始連麥',
        '对方主播接受了您的连麦请求，开始连麦': '對方主播接受了您的連麥請求，開始連麥',
        '主播接受了您的PK请求，开始PK': '主播接受了您的PK請求，開始PK',
        '连麦推流失败，已退出连麦': '連麥推流失敗，已退出連麥',
        '连麦请求已发送': '連麥請求已發送',
        'PK请求已发送': 'PK請求已發送',
        '对方发起PK': '對方發起PK',
        '连麦时无法开启伴奏': '連麥時無法開啟伴奏',
        '连麦时需要关闭背景音乐': '連麥時需要關閉背景音樂',
        '直播记录': '直播記錄',
        '无标题': '無標題',
        '人看过': '人看過',
        '游戏状态不能进行连麦哦': '遊戲狀態不能進行連麥哦',
        '连麦状态不能进行游戏哦': '連麥狀態不能進行遊戲哦',
        '主播正在忙碌': '主播正在忙碌',
        '当前正在进行连麦': '當前正在進行連麥',
        '你已经在PK中': '你已經在PK中',
        '我方': '我方',
        '对方': '對方',
        '余额不足，是否要充值？': '餘額不足，是否要充值？',
        '当前在线主播': '當前線上主播',
        '对方主播在游戏中': '對方主播在遊戲中',
        '邀请连麦': '邀請連麥',
        '已邀请': '已邀請',
        '请输入您要搜索的主播昵称或ID': '請輸入您要蒐索的主播昵稱或ID',
        '暂时没有主播': '暫時沒有主播',
        '没有搜索到相关内容': '沒有蒐索到相關內容',
        'PK时间': 'PK時間',
        '惩罚时间': '懲罰時間',
    
        '首页': '首頁',
        '附近': '附近',
        '动态': '動態',
        '排行': '排行',
        '商城': '商城',
        '我的': '我的',
        '普通房间': '普通房間',
        '付费房间': '付費房間',
        '计时房间': '計時房間',
        '密码房间': '密碼房間',
        '大咖榜': '大咖榜',
        '土豪榜': '土豪榜',
        '日榜': '日榜',
        '周榜': '周榜',
        '月榜': '月榜',
        '总榜': '總榜',
        '维护通知': '維護通知',
        '虚位以待': '虛位以待',
        '暂时空缺': '暫時空缺',
        '立即直播': '立即直播',
        '发布视频': '發佈視頻',
        '请填写邀请码': '請填寫邀請碼',
        '再按一次退出': '再按一次退出',
        '推荐主播': '推薦主播',
        '热门主播': '熱門主播',
        '全部查看': '全部查看',
        '旧密码': '舊密碼',
        '请输入旧密码': '請輸入舊密碼',
        '新密码': '新密碼',
        '请填写新密码': '請填寫新密碼',
        '确认密码': '確認密碼',
        '确认新密码': '確認新密碼',
        '立即修改': '立即修改',
        '重置密码': '重置密碼',
        'QQ': 'QQ',
        'QQ空间': ' QQ空間',
        '微信': '微信',
        '朋友圈': '朋友圈',
        'Facebook': 'Facebook',
        '推特': '推特',
        '搜索歌曲': '蒐索歌曲',
        '暂无下载歌曲': '暫無下載歌曲',
        '歌曲下载地址无效': '歌曲下載地址無效',
        '歌词未找到': '歌詞未找到',
        '歌曲未找到': '歌曲未找到',
        '音乐': '音樂',
        '配乐': '配樂',
        '音量': '音量',
        '暂无音乐': '暫無音樂',
        '你还没有收藏任何音乐': '你還沒有收藏任何音樂',
        '暂无此类音乐': '暫無此類音樂',
        '去看看其他音乐类型吧': '去看看其他音樂類型吧',
        '$': '$',
        '您拒绝了': '您拒絕了%1$s的許可權，請到設定中修改',
        '档案读写': '檔案讀寫',
        '使用监视器': '使用監視器',
        '使用麦克风': '使用麥克風',
        '使用定位': '使用定位',
        '读取手机信息': '讀取手機資訊',
        '读取应用清单': '讀取應用清單',
        '您拒绝了显示悬浮窗的权限，请到设定中修改': '您拒絕了顯示懸浮窗的許可權，請到設定中修改',
        '收入': '收入',
        '可到账金额': '可到賬金額',
        '请选择提现账户': '請選擇提現帳戶',
        '输入要提现的': '輸入要提現的%1$s數',
        '您输入的金额大于可提现金额': '您輸入的金額大於可提現金額',
        '总数': '總數',
        '可选取': '可選取%1$s數',
        '输入要选取的数量': '輸入要選取的數量',
        '立即提现': '立即提現',
        '注册': '注册',
        '请输入您的手机号': '請輸入您的手機號',
        '请输入验证码': '請輸入驗證碼',
        '请填写密码': '請填寫密碼',
        '请确认密码': '請確認密碼',
        '注册并登入': '注册並登入',
        '验证码': '驗證碼',
        '重新获取': '重新獲取',
        '密码不一致': '密碼不一致',
        '注册中': '注册中',


        '直播间红包': '直播間紅包',
        '给当前直播间观众发红包': '給當前直播間觀眾發紅包',
        '拼手气红包': '拼手氣紅包',
        '平均红包': '平均紅包',
        '恭喜发财，大吉大利': '恭喜發財，大吉大利',
        '发红包': '發紅包',
        '请输入金额': '請輸入金額',
        '请输入数量': '請輸入數量',
        '个红包': '個紅包',
        '抢': '搶',
        '派发了一个实时红包': '派發了一個即時紅包',
        '派发了一个延时红包': '派發了一個延時紅包',
        
        '查看领取详情': '查看領取詳情',
        '恭喜你！': '恭喜你！',
        '抢到': '搶到',
        '的红包': '%1$s的紅包',
        '已存入「我的」': '已存入「我的%1$s」',
        '已领取': '已領取',
        '未抢到': '未搶到',
        '倒数计时结束后可抢': '倒數計時結束後可搶',
        '在直播间发红包啦！ 快去抢哦～': '在直播間發紅包啦！ 快去搶哦～',
        '单个金额': '單個金額',
        '总金额': '總金額',
        '推荐': '推薦',
        '跳过': '跳過',
        '点击进入': '點擊進入',
        '为你推荐': '為你推薦',
        '男': '男',
        '女': '女',
        '设定': '設定',
        '退出': '登出',
        '清除中': '清除中',
        '缓存已清除': '緩存已清除',
        '夜间模式': '夜間模式',
        '声音音效': '聲音音效',
        '小窗模式': '小窗模式',
        '语言切换': '語言切換',
        '搜索': '蒐索',
        '搜索内容…': '蒐索內容…',
        '请输入您要搜索的昵称或ID': '請輸入您要蒐索的昵稱或ID',
        '没有搜索到相关内容': '沒有蒐索到相關內容',
        'QQ': 'QQ',
        'QQ空间': 'QQ空間',
        '微信': '微信',
        '朋友圈': '朋友圈',
        'facebook': 'facebook',
        'twitter': 'twitter',
        '分享成功': '分享成功',
        '分享失败': '分享失敗',
        '分享取消': '分享取消',
    
        '版本更新': '版本更新',
        '立即使用': '立即使用',
        '暂不更新': '暫不更新',
        '已经是最新版本': '已經是最新版本',
        '下载地址无效': '下載地址無效',
        '说点什么': '說點什麼',
        '视频下载成功': '視頻下載成功',
        '视频下载失败': '視頻下載失敗',
        '没有更多视频': '沒有更多視頻',
        '评论': '評論',
        '不能给自己的评论点赞': '不能給自己的評論點贊',
        '不能回复自己': '不能回復自己',
        '暂无评论，快来抢沙发吧': '暫無評論，快來搶沙發吧',
        '回复': '回復',
        '回复给：': '回復給：',
        '展开更多回复': '展開更多回復',
        '收起': '收起',
        '翻转': '翻轉',
        '闪光灯': '閃光燈',
        '监视器打开失败': '監視器打開失敗',
        '麦克风打开失败': '麥克風打開失敗',
        '录制失败': '錄製失敗',
        '别着急，画面还没出来': '別著急，畫面還沒出來',
        '还有录制的任务没有结束': '還有錄製的任務沒有結束',
        '传入的视频路径为空': '傳入的視頻路徑為空',
        '版本太低': '版本太低',
        '腾讯云鉴权失败': '騰訊雲鑒權失敗',
        '极慢': '極慢',
        '慢': '慢',
        '正常': '正常',
        '快': '快',
        '极快': '極快',
        '视频预处理中…': '視頻預處理中…',
        '视频预处理失败': '視頻預處理失敗',
        '视频预处理取消': '視頻預處理取消',
        
        '处理中': '處理中',
        '状态异常，停止编辑': '狀態异常，停止編輯',
        '选择音乐': '選擇音樂',
        '搜索歌曲名称': '蒐索歌曲名稱',
        '热门歌曲': '熱門歌曲',
        '我的收藏': '我的收藏',
        '滤镜': '濾鏡',
        '裁剪': '裁剪',
        '特效': '特效',
        '截取所需音讯片段': '截取所需音訊片段',
        '原声': '原聲',
        '请拖拽两侧滑块选择裁剪区域': '請拖拽兩側滑塊選擇裁剪區域',
        '添加视频描述': '添加視頻描述',
        '确认发布': '確認發佈',
        '视频发布': '視頻發佈',
        '视频生成中': '視頻生成中',
        '生成视频成功': '生成視頻成功',
        '生成视频失败': '生成視頻失敗',
        '是否放弃发布此条视频': '是否放弃發佈此條視頻',
        '放弃': '放弃',
        '未找到本地视频': '未找到本地視頻',
        '本地视频': '本地視頻',
        '视频时长不符合要求': '視頻時長不符合要求',
        '生成视频封面图失败': '生成視頻封面圖失敗',
        '请添加视频描述': '請添加視頻描述',
        '发布中': '發佈中',
        '发布成功': '發佈成功',
        '上传成功': '上傳成功，請等待稽核',
        '上传失败': '上傳失敗',
        '请上传视频': '請上傳視頻',
        '仅保存': '僅保存',
        '仅发布': '僅發佈',
        '保存并发布': '保存並發佈',
        '重新拍摄': '重新拍攝',
        '退出': '退出',
        '长按可添加特效': '長按可添加特效',
        '发布失败': '發佈失敗',
        '你还没有视频作品': '你還沒有視頻作品',
        '赶快去拍摄上传吧': '趕快去拍攝上傳吧',
        'TA还没有视频作品': 'TA還沒有視頻作品',
        '你赞过的作品都会放到这里': '你贊過的作品都會放到這裡',
        '我的视频': '我的視頻',
        '我的喜欢': '我的喜歡',
        '选择举报理由': '選擇舉報理由',
        '更多详细信息请在说明框中描述（选填）': '更多詳細資訊請在說明框中描述（選填）',
        '提交': '提交',
        '请选择举报理由': '請選擇舉報理由',
        '举报成功': '舉報成功',
        '已经到顶了哦': '已經到頂了哦',
        '确定删除上一段视频？': '確定删除上一段視頻？',
        '是否退出视频编辑': '是否退出視頻編輯',
        '添加': '添加',
        '已添加': '已添加',
        '接受': '接受',
        '全部': '全部',
        '相册': '相册',
        '取消': '取消',
        '拍照': '拍照',
        '确定': '確定',
         '请输入内容': '請輸入內容',
        '选择': '選擇',
        '选择档案': '選擇檔案',
        '无法关注自己哦': '無法關注自己哦',
        '提示': '提示',
        '下载': '下載',
        '删除': '删除',
        '结束': '結束',
        '热门': '熱門',
        '网络请求失败': '網絡請求失敗',
        '请检查网络连接后重试': '請檢查網絡連接後重試',
        '加载中': '加載中',
        '位置': '位置',
        '没有更多数据': '沒有更多數據',
        '暂无数据': '暫無數據',
        '附近': '附近',
        '万': '萬',
        '请稍等': '請稍等',
        '私信': '私信',
        '举报': '舉報',
        '重试': '重試',
        '拒绝': '拒絕',
        '保存': '保存',
        '发送': '發送',
        '对不起': '對不起',
        '视频': '視頻',
        '人': '%1$s人',
        '个': '個',
        '数量': '數量',
        '下一步': '下一步',
        '上传': '上傳',
        '我的直播间': '我的直播間',
        '我的房间': '我的房間',
        '管理员': '管理員',
        '拉黑用户': '拉黑用戶',
        '禁言用户': '禁言用戶',
        '禁言用户列表': '禁言用戶列表',
        '的直播间': '的直播間',
        '解除禁言': '解除禁言',
        '拉黑用户列表': '拉黑用戶列表',
        '没有被禁言用户': '沒有被禁言用戶',
        '没有被拉黑用户': '沒有被拉黑用戶',
        '你还不是管理员': '你還不是管理員',
        '解除拉黑': '解除拉黑',
        '喜中': '喜中',
        '邀请码': '邀請碼',
        '恭喜': '恭喜',
        '幸运礼物说明': '幸運禮物說明',
        '道具礼物说明': '道具禮物說明',
        '当前奖池等级': '當前獎池等級',
        '当前奖池金额': '當前獎池金額',
        'Lv.': 'Lv.',
        '发送弹幕，需要到达': '發送彈幕，需要到達%1$s級',
        '发言，需要到达': '發言，需要到達%1$s級',
        '开定位': '開定位',
        '坚决关闭': '堅決關閉',
        '关闭定位，直播不会被附近的人看到，直播间人数可能会减少，确认关闭吗？': '關閉定位，直播不會被附近的人看到，直播間人數可能會减少，確認關閉嗎？',
        '火星': '火星',
        '道具': '道具',
        '明细': '明細',
        '储值': '儲值',
        '个性签名': '個性簽名',
        '个人信息': '個人資訊',
        '请选择支付方式': '請選擇支付方式',
        '支出': '支出',
        '收入': '收入',
        '《用户充值协议》': '《用戶充值協議》',
        '已阅读并同意': '已閱讀並同意',
        '请选择充值金额': '請選擇充值金額',
        '未选中支付方式': '未選中支付方式',
        '我的': '我的',
        '确认支付': '確認支付',
        '支付方式': '支付方式',
        '立即支付': '立即支付',
        '使用者等级': '使用者等級',
        '主播等级': '主播等級',
        '连接已断开，请重新开播': '連接已斷開，請重新開播',
        '空空如也': '空空如也',
        '游戏规则': '遊戲規則',
        '中奖记录': '中獎記錄',
        '转': '轉%1$s次',
        '获得的礼物将存放在礼物背包中': '獲得的禮物將存放在禮物背包中',
        '开启购物车': '開啟購物車',
        '关闭购物车': '關閉購物車',
        '淘宝商品': '淘寶商品',
        '商城小程序': '商城小程式',
        '商品连结': '商品連結',
        '商品名称（最多可输入20个字）': '商品名稱（最多可輸入20個字）',
        '原价': '原價',
        '现价': '現價',
        '确认添加商品': '確認添加商品',
        '商品简介（最多可输入50个字）': '商品簡介（最多可輸入50個字）',
        '商品图片': '商品圖片',
        '查看次数：': '查看次數',
        '（输入小程序中商品对应ID，将自动获取商品信息）': '（輸入小程式中商品對應ID，將自動獲取商品資訊）',
        '商品名称': '商品名稱',
        '商品简介': '商品簡介',
        '商品ID': '商品ID',
        '获取商品信息': '獲取商品資訊',
        '查看商品详情': '查看商品詳情',
        '广告连结无效': '廣告連結無效',
        '在售商品': '在售商品',
        '去看看': '去看看',
        '当前暂无在售商品': '當前暫無在售商品',
        '全部商品': '全部商品',
        '添加商品': '添加商品',
        '移除': '移除',
        '当前暂无在售商品\n点击右上角添加商品到购物车': '當前暫無在售商品\n點擊右上角添加商品到購物車',
        '请输入商品名称': '請輸入商品名稱',
        '您的店铺内暂无商品\n请先前往店铺内添加商品': '您的店鋪內暫無商品\n請先前往店鋪內添加商品',
        '在售商品': '在售商品',
        '进店逛逛': '進店逛逛',
        '推荐商品': '推薦商品',
        '去购买': '去購買',
        '下架': '下架',
        '关联商品': '關聯商品',
        '查看商品详情': '查看商品詳情',
        '查看详情': '查看詳情',
        '广告连结无效': '廣告連結無效',
        '重新上架': '重新上架',
        '删除后店铺内将不再显示此款商品\n确定要删除此款商品吗？': '删除後店鋪內將不再顯示此款商品\n確定要删除此款商品嗎？',
        '下架后用户将无法购买此款商品\n确定要下架此款商品吗？': '下架後用戶將無法購買此款商品\n確定要下架此款商品嗎？',
        '请输入商品id': '請輸入商品id',
        '管理下架': '管理下架',
        '已下架': '已下架',
        '展示': '展示',
        '所有照片': '所有照片',
        '完成': '完成',
        '拍摄': '拍攝',
        '最多选': '最多選張圖片哦',
        '发布动态': '發佈動態',
        '发布': '發佈',
        '分享你的想法…': '分享你的想法…',
        '所在位置': '所在位置',
        '预览': '預覽',
        '我的动态': '我的動態',
        '所在位置': '所在位置',
        '不显示位置': '不顯示位置',
        '录音': '錄音',
        '点击可录音': '點擊可錄音',
        '点击可暂停': '點擊可暫停',
        '请先录音': '請先錄音',
        '录音结束': '錄音結束',
        '使用视频': '使用視頻',
        '删除视频': '删除視頻',
        '确定放弃当前编辑内容？': '確定放弃當前編輯內容？',
        '点赞': '點贊',
        '待稽核': '待稽核',
        '失败':'失敗',
        '未通过': '未通過',
        '说说你的想法…': '說說你的想法…',
        '动态详情': '動態詳情',
        '暂无评论，快来抢沙发吧': '暫無評論，快來搶沙發吧',
        '全部评论': '全部評論',
        '\@TA': '\@TA',
        '的直播間送出': '的直播間送出',
        '账号已被封禁': '帳號已被封禁',
        '封禁说明：': '封禁說明：',
        '封禁时长：': '封禁時長：',
        '知道了': '知道了',
        '视频分类': '視頻分類',
        '所有分类': '所有分類',
        '选择视频分类': '選擇視頻分類',
        '请选择视频分类': '請選擇視頻分類',
        '发送了': '發送了',
        '礼物道具':'道具禮物',
        '下一个': '下一個',
        '本房间为密码房间，请输入密码': '本房間為密碼房間，請輸入密碼',
        '本房间为收费房间，需要支付': '本房間為收費房間，需要支付%1$s%2$s',
        '本房间为计时房间，每分钟需要支付': '本房間為計時房間，每分鐘需要支付%1$s%2$s',
        '已经是最后一个': '已經是最後一個',
        '重拍': '重拍',
        '使用视频': '使用視頻',
        '云豹小店': '雲豹小店',
        '浏览记录': '瀏覽記錄',
        '我的地址': '我的地址',
        '账户余额': '帳戶餘額',
        '我的订单': '我的訂單',
        '查看全部订单': '查看全部訂單',
        '待付款': '待付款',
        '待发货': '待發貨',
        '待收货': '待收貨',
        '待评估': '待評估',
        '退款': '退款',
        '我要开店': '我要開店',
        '开小店，来赚钱': '開小店，來賺錢',
        '开通小店': '開通小店',
        '信息确认': '資訊確認',
        '账号ID:': '帳號ID:',
        '姓名：': '姓名：',
        '身份证号：': '身份證號：',
        '1.请确认上述账号ID、姓名、身份证号为本人信息。 若非本人信息请停止操作。': '1.請確認上述帳號ID、姓名、身份證號為本人資訊。 若非本人資訊請停止操作。',
        '2.您同意授权本平台公司获取并保存账号ID、姓名、身份证号个人信息用于身份认证备案。': '2.您同意授權本平臺公司獲取並保存帳號ID、姓名、身份證號個人資訊用於身份認證備案。',
        '3.您承诺通过本平台小店开展的经营活动为根据法律法规规定不需要办理市场主体登记的经营行为。': '3.您承諾通過本平臺小店開展的經營活動為根據法律法規規定不需要辦理市場主體登記的經營行為。',
        '经营类目': '經營類目',
        '请选择': '請選擇',
        '经营者联系方式': '經營者聯繫方式',
        '连络人': '連絡人',
        '手机号': '手機號',
        '所在地区': '所在地區',
        '详细地址': '詳細地址',
        '客服信息': '客服資訊',
         '（不填默认设置为连络人手机号）': '（不填默認設置為連絡人手機號）',
        '客服电话': '客服電話',
        '退货信息': '退貨資訊',
        '（不填默认设置为经营地址、电话信息）': '（不填默認設置為經營地址、電話資訊）',
        '收货人': '收貨人',
        '收货人手机号': '收貨人手機號',
        '资质证明': '資質證明',
        '营业执照': '營業執照',
        '其他证件': '其他證件',
        '缴纳保证金': '繳納保證金',
         '提交': '提交',
         '请谨慎选择，主营类目设定成功后将不可更改': '請謹慎選擇，主營類目設定成功後將不可更改',
       '选择主营类目': '選擇主營類目',
        '经营类目设定': '經營類目設定',
         '主营类目最多可选择三项': '主營類目最多可選擇三項',
        '请选择经营类目': '請選擇經營類目',
        '已选择': '已選擇',
        '开店保证金': '開店保證金',
        '需要缴纳金额': '需要繳納金額',
        '保证金说明': '保證金說明',
        '1、保证金由商户交由平台暂时保管，用于约束商户行为，保障消费者权益。': '1、保證金由商戶交由平臺暫時保管，用於約束商戶行為，保障消費者權益。',
        '2、用户撤销%1$s时，可申请退还保证金。': '2、用戶撤銷%1$s時，可申請退還保證金。',
        '3、当用户开通店铺后，若存在欺骗消费者、售卖假冒伪劣产品等一切违反国家法律法规以及平台规定的等行为，平台有权强制关闭店铺，保证金不予退还。': '3、當用戶開通店鋪後，若存在欺騙消費者、售賣假冒偽劣產品等一切違反國家法律法規以及平臺規定的等行為，平臺有權強制關閉店鋪，保證金不予退還。',
        '4、店铺保证金最终解释权归平台所有。': '4、店鋪保證金最終解釋權歸平臺所有。',
        '确认缴纳': '確認繳納',
        '已缴纳保证金': '已繳納保證金',
        '请输入姓名': '請輸入姓名',
        '请输入身份证号': '請輸入身份證號',
         '请输入经营者姓名': '請輸入經營者姓名',
        '请输入经营者手机号': '請輸入經營者手機號',
        '请输入经营者所在区域': '請輸入經營者所在區域',
         '请输入经营者详细地址': '請輸入經營者詳細地址',
        '请设定营业执照': '請設定營業執照',
        '请先缴纳保证金': '請先繳納保證金',
        '信息稽核中…': '資訊稽核中…',
        '身份信息稽核未通过': '身份資訊稽核未通過',
        '重新认证': '重新認證',
         '3个工作日内会有稽核结果，请耐心等待': '3個工作日內會有稽核結果，請耐心等待',
        '（卖家端）': '（賣家端）',
        '暂无商品': '暫無商品',
        '综合分': '綜合分',
        '账户金额（元）': '帳戶金額（元）',
         '累计收入（元）': '累計收入（元）',
        '订单管理': '訂單管理',
        '添加商品': '添加商品',
        '商品管理': '商品管理',
        '地址管理': '地址管理',
        '选择商品类别': '選擇商品類別',
        '商品类别': '商品類別',
        '请选择': '請選擇',
        mall_080: '商品標題',
        mall_081: '建議填寫產品詞、内容詞、行銷詞等，不超過15個字',
        mall_082: '商品視頻',
        mall_083: '商品圖',
        mall_084: '商品詳情',
        mall_085: '建議通過細節說明、規格、搭配、購買須知等多方面描述商品的詳情，不超過300個字',
        mall_086: '詳情圖',
        mall_087: '編輯規格',
        mall_088: '最多15個字元',
        mall_089: '規格',
        mall_090: '庫存（件）',
        mall_091: '最多9999999件',
        mall_092: '單價（元）',
        mall_093: '最少1.00元',
        mall_094: '上傳圖片',
        mall_095: '新增規格',
        mall_096: '運費設定',
        mall_097: '包郵',
        mall_098: '提交稽核',
        mall_099: '删除規格',
        mall_100: '運費（元）',
        mall_101: '請選擇商品類別',
        mall_102: '請輸入商品標題',
        mall_103: '請輸入商品詳情',
        mall_104: '請輸入郵費',
        mall_105: '請輸入規格名稱',
        mall_106: '請輸入庫存',
        mall_107: '請輸入單價',
        mall_108: '請設定商品圖片',
        mall_109: '在售',
        mall_110: '稽核',
        mall_111: '已下架',
        mall_112: '價格與庫存',
        mall_113: '下架',
        mall_114: '已售%1$s件',
        mall_115: '你還沒有相關商品',
        mall_116: '編輯',
        mall_117: '稽核中',
        mall_118: '上架',
        mall_119: '預覽商品',
        mall_120: '商品詳情',
        mall_121: '選擇',
        mall_122: '請選擇規格',
        mall_123: '服務',
        mall_124: '已交保證金',
        mall_125: '資質保障',
        mall_126: '進店逛逛',
        mall_127: '總銷量',
        mall_128: '商品品質',
        mall_129: '服務態度',
        mall_130: '物流服務',
        mall_131: '寶貝評估',
        mall_132: '寶貝詳情',
        mall_133: '還沒有收到任何評估',
        mall_134: '暫無詳情',
        mall_135: '立即購買',
        mall_136: '店鋪',
        mall_137: '客服',
        '个人主页': '個人主頁',
        file_not_exist: '檔案不存在',
        mall_140: '基礎資訊',
        mall_141: '資質證明',
        mall_142: '查看資質證明',
        mall_143: '實名認證',
        mall_144: '已認證',
        mall_145: '店鋪保證金',
        mall_146: '已繳納',
        mall_147: '小店詳情',
        mall_148: '退貨地址管理',
        mall_149: '編輯收貨地址',
        mall_150: '請輸入姓名',
        mall_151: '請輸入聯繫電話',
        mall_152: '請輸入所在地區',
        mall_153: '請輸入詳細地址',
        mall_154: '新增收貨地址',
        mall_155: '還沒有收貨地址',
        mall_156: '我的地址',
        mall_157: '保存並使用',
        mall_158: '姓名',
        mall_159: '聯繫電話',
        mall_160: '設為默認地址',
        mall_161: '默認',
        mall_162: '編輯收貨地址',
        mall_163: '確定要删除嗎？',
        mall_164: '進入小店',
        mall_165: '共%1$s件商品',
        mall_167: '在售商品',
        mall_168: '%1$s件',
        manage: '管理',
        mall_169: '瀏覽記錄',
        mall_170: '全選',
        mall_171: '近期暫無瀏覽記錄',
        mall_172: '服務保障',
        mall_173: '已交保證金',
        mall_174: '商家已向平臺繳納保證金，交易產生糾紛時用於保證買家的權益',
        mall_175: '商家已向平臺提交經營執照、許可資質等相關資質證明。',
        mall_176: '立即購買',
        mall_177: '庫存：%1$s',
        mall_178: '已選：%1$s',
        mall_179: '運費：%1$s元',
        mall_180: '郵費',
        mall_181: '包郵',
        mall_182: '總計',
        mall_183: '買家留言：',
        mall_184: '建議留言前先與商家溝通確認',
        mall_185: '應付：',
        mall_186: '提交訂單',
        mall_187: '確認訂單',
        mall_188: '規格：%1$s',
        mall_189: '請設定收貨地址',
        mall_190: '收銀台',
        mall_191: '%1$s支付',
        mall_192: '我的訂單',
        mall_193: '全部',
        mall_194: '你還沒有相關訂單',
        mall_195: '共%1$s件商品，合計',
        mall_196: '取消訂單',
        mall_197: '付款',
        mall_198: '删除訂單',
        mall_199: '查看物流',
        mall_201: '評估',
        mall_202: '退款詳情',
        mall_203: '待支付',
        mall_204: '等待退款',
        mall_205: '其他',
        mall_206: '訂單號：%1$s',
        mall_207: '買家昵稱：%1$s',
        mall_208: '共%1$s件商品',
        mall_209: '去發貨',
        mall_211: '聯系買家',
        mall_212: '%1$s，金額%2$s%3$s',
        mall_213: '退款',
        mall_214: '已發貨',
        mall_215: '已簽收',
        mall_216: '已完成',
        mall_217: '已關閉',
        mall_218: '退款訂單',
        mall_219: '已發貨訂單',
        mall_220: '已簽收訂單',
        mall_221: '已完成訂單',
        mall_222: '已關閉訂單',
        mall_223: '全部訂單',
        mall_225: '填寫物流單號',
        mall_226: '選擇物流公司',
        mall_227: '確認發貨',
        mall_228: '複製地址',
        mall_230: '運費',
        mall_231: '需付款/實付款',
        mall_232: '訂單編號：%1$s',
        mall_233: '訂單詳情',
        mall_234: '描述相符',
        mall_235: '發表',
        mall_236: '發表評估',
        mall_237: '寶貝滿足你的期望嗎？ 說說它的優點和不足吧～',
        mall_237_1: '已經用了一段時間了，有更多寶貝使用心得？ 分享給想買的他們吧',
        mall_238: '公開',
        mall_239: '店鋪評估',
        mall_240: '送貨服務',
        mall_241: '服務態度',
        mall_242: '添加圖片',
        mall_243: '添加視頻',
        mall_244: '匿名',
        mall_245: '評論內容不能為空',
        mall_246: '發表追評',
        mall_247: '追加評估',
        mall_248: '聯系客服',
        mall_249: '撥打電話',
        copy: '複製',
        mall_250: '下單時間：%1$s',
        mall_251: '支付方式：%1$s',
        mall_252: '支付時間：%1$s',
        mall_253: '確認收貨',
        mall_254: '退款管道',
        mall_255: '僅退款',
        mall_256: '退貨退款',
        mall_257: '退款原因',
        mall_258: '退款金額',
        mall_259: '請選擇',
        mall_260: '請填寫10個字以上的問題描述以便我們提供更好的幫助',
        mall_261: '提交申請',
        mall_262: '退款申請',
        mall_263: '請選擇退款原因',
        mall_264: '取消申請',
        mall_265: '重新申請',
        mall_266: '平臺介入',
        mall_267: '協商歷史',
        mall_268: '退款資訊',
        mall_269: '退款管道：',
        mall_270: '退款金額：',
        mall_271: '退款原因：',
        mall_272: '申請時間：',
        mall_2721: '退款單號：',
        mall_273: '問題描述：',
        mall_274: '拒絕原因：',
        mall_275: '拒絕描述：',
        mall_276: '取消退款申請後，該訂單無法再次申請退款，確定取消？',
        mall_277: '同意',
        mall_278: '訂單狀態：',
        mall_279: '買家昵稱：',
        mall_280: '選擇拒絕前請先嘗試與買家進行充分溝通',
        mall_281: '拒絕退款',
        mall_282: '拒絕原因',
        mall_283: '請填寫詳細原因與證據描述，有助於快速解决你的問題',
        mall_284: '請選擇拒絕原因',
        mall_285: '繼續',
        mall_286: '平臺介入',
        mall_287: '申訴原因',
        mall_288: '申訴原因',
        mall_289: '查看更多評估',
        mall_290: '有圖',
        mall_291: '追加',
        mall_292: '全部評估',
        mall_293: '帳戶餘額（元）',
        mall_294: '退款記錄',
        mall_295: '選取餘額',
        mall_296: '無相關退款記錄',
        mall_297: '帳單管理',
        mall_298: '累計收入',
        mall_299: '結算記錄',
        mall_300: '小店帳戶金額',
        mall_301: '選取收益',
        mall_302: '交易中',
        mall_303: '無相關結算記錄',
        mall_304: '可選取金額',
        mall_305: '輸入選取金額',
        mall_306: '請輸入提現金額',
        mall_307: '預覽不支持該功能',
        mall_308: '申請',
        mall_309: '我購買的',
        mall_310: '申請說明',
        mall_311: '我已閱讀並同意',
        mall_312: '立即申請',
        mall_313: '我知道了',
        mall_314: '我上傳的',
        mall_315: '上傳付費內容',
        mall_317: '內容標題',
        mall_318: '建議填寫關鍵字、内容詞、行銷詞等，不超過15個字',
        mall_319: '內容封面',
        mall_320: '封面圖',
        mall_321: '內容簡介',
        mall_322: '建議通過視頻內容、故事情節、學習技巧等方面說明內容特點，不超過100個字',
        mall_323: '個人介紹',
        mall_324: '建議填寫作者身份、取得的成就、過往作品等，不超過50個字',
        mall_325: '內容價格',
        mall_326: '上傳視頻',
        mall_200: '確認收貨',
        mall_229: '買家留言',
        mall_316: '內容類別',
        mall_327: '單視頻',
        mall_328: '多視頻',
        mall_329: '小標題',
        mall_330: '最多15個字',
        mall_331: '新增視頻',
        mall_332: '試看',
        mall_333: '請選擇視頻',
        mall_334: '請設定視頻標題',
        mall_335: '請選擇類別',
        mall_336: '請輸入內容標題',
        mall_337: '請輸入內容簡介',
        mall_338: '請輸入個人介紹',
        mall_339: '請輸入價格',
        mall_340: '請選擇封面圖片',
        mall_341: '%1$s人已購買',
        mall_342: '已拒絕',
        mall_343: '視頻詳情',
        mall_345: '視頻簡介',
        mall_346: '觀眾評估',
        mall_347: '選集',
        mall_348: '作者資訊',
        mall_349: '時長：%1$s',
        mall_350: '播放',
        mall_351: '%1$s人已評估',
        mall_352: '評估視頻',
        mall_353: '觀看視頻',
        mall_354: '小店商品',
        mall_355: '付費內容',
        mall_357: '你還沒有購買過付費內容',
        mall_358: '你還沒有發佈過付費內容',
        mall_356: '查看付費內容',
        mall_359: '購買付費內容',
        mall_360: '極不滿意',
        mall_361: '不滿意',
        mall_362: '滿意',
        mall_363: '很滿意',
        mall_364: '非常滿意',
        mall_365: '訂單消息',
        mall_366: ' 點擊查看',
        mall_367: '付款失敗',
        mall_369: '（買家端）',
        mall_370: '確定删除訂單？',
        mall_371: '確定取消訂單？',
        mall_372: '確定已收到商品？',
        mall_373: '該商品已下架',
        not_use: '暫不使用',
        mall_374: '站內商品',
        mall_375: '站外商品',
        mall_376: '站外',
        mall_377: '此商品屬於站外連結商品，可能存在一定風險，請謹慎購買',
        mall_378: '繼續購買',
        mall_379: '商品連結不存在',
        mall_380: '已拒絕',
        '确认删除？': '確認删除？',
        mall_381: '確定下架商品？',
        mall_382: '請輸入付費內容名稱',
        mall_383: '確定上架商品？',
        mall_384: '確定删除商品？',
        mall_385: '請設定收貨人資訊',
        mall_386: '請同意條款',
        minute: '分鐘',
        '注销账号': '註銷帳號',
        '确认注销': '確認註銷',
        '点击确认注销后，账号将立即注销': '點擊確認註銷後，帳號將立即註銷',
        '*短信验证保障账户安全的同时简讯费用将由平台支付': '*簡訊驗證保障帳戶安全的同時簡訊費用將由平臺支付',
        '满足以下条件，才能注销当前账户': '滿足以下條件，才能註銷當前帳戶',
        '注销条件': '註銷條件',
        '已通过': '已通過',
        '未通过': '未通過',
        mall_387: '請輸入您要蒐索的商品名稱',
        mall_388: '暫無商品',
        mall_389: '新品',
        mall_390: '銷量',
        mall_391: '價格',
        mall_392: '前去購買',
        mall_393: '收藏',
        mall_394: '我的收藏',
        '搜索您感兴趣的话题': '蒐索您感興趣的話題',
        '话题': '話題',
        '全部话题': '全部話題',
        '选择话题': '選擇話題',
         '参与话题，让更多人看到': '參與話題，讓更多人看到',
        '推荐话题': '推薦話題',
        '搜索您感兴趣的话题': '蒐索您感興趣的話題',
        '热门话题': '熱門話題',
         '查看更多': '查看更多',
        '每日任务': '每日任務',
        '未达成': '未達成',
        '可领取': '可領取',
        '已领取': '已領取',
        '绘制礼物，进入涂鸦模式': '繪製禮物，進入塗鴉模式',
        '最少绘制10个礼物': '最少繪製10個禮物',
        '涂鸦': '塗鴉',
        '撤回': '撤回',
        '你撤回了一条消息': '你撤回了一條消息',
        '对方撤回了一条消息': '對方撤回了一條消息',
       '该消息发送时间已超过两分钟，无法撤回': '該消息發送時間已超過兩分鐘，無法撤回',
        '撤回失败': '撤回失敗',
        '保存到本地相册': '保存到本地相册',
        '保存成功': '保存成功',
        '确定登出吗？': '確定登出嗎？',
        '退出': '登出',
        mall_395: '經營類別',
        mall_396: '管理員正在飛速稽核中~',
        mall_397: '您的稽核沒有通過，請重新提交',
        mall_398: '重新提交',
        mall_399: '經營類目設定',
        mall_400: '請謹慎選擇，主營類目設定成功後將不可更改',
        mall_401: '選擇主營類目',
        mall_402: '暫無收藏',
        mall_403: '商品已下架',
        mall_404: '我的商品',
        mall_405: '平臺自營',
        mall_406: '平臺商品',
        mall_407: '暫時沒有平臺商品',
        mall_408: '傭',
        mall_409: '代賣',
        mall_410: '取消代賣',
        mall_411: '確定取消代賣該商品？',
        mall_412: '請前往個人中心-聯繫我們中聯系平臺',
        mall_413: '分享傭金設定',
        mall_414: '傭金（元）',
        '开启聊天室': '開啟聊天室',
        '幸运奖池': '幸運獎池',
        '幸运抽奖': '幸運抽獎',
         '手机号归属地': '手機號歸屬地',
        '请输入地区名': '請輸入地區名',
        '以下信息均为必填项，为保证您的利益，请如实填写': '以下資訊均為必填項，為保證您的利益，請如實填寫',
        '真实姓名': '真實姓名',
        '请填写您的真实姓名': '請填寫您的真實姓名',
        '手机号码': '手機號碼',
        '请填写您的手机号': '請填寫您的手機號',
         '身份证号': '身份證號',
        '请填写您的身份证号': '請填寫您的身份證號',
        '证件正面': '證件正面',
        '证件背面': '證件背面',
        '手持证件正面照': '手持證件正面照',
        '（手持证件照示例）': '（手持證件照示例）',
        '请按图示手持证件拍照\n确保证件信息清晰可见': '請按圖示手持證件拍照\n確保證件資訊清晰可見',
        '提交认证': '提交認證',
        '申请认证': '申請認證',
        '开通店铺保证金不足\n请先进行充值': '開通店鋪保證金不足\n請先進行充值',
         '立刻充值': '立刻充值',
        '家族申请': '家族申請',
         '家族名称': '家族名稱',
        '请输入您要创建的家族名称': '請輸入您要創建的家族名稱',
         '个人姓名': '個人姓名',
       '请输入您的姓名': '請輸入您的姓名',
        '抽成比例': '抽成比例',
         '请填写0-100之间的整数': '請填寫0-100之間的整數',
       '家族简介': '家族簡介',
        '请简单介绍一下您的家族': '請簡單介紹一下您的家族',
        '证件照片': '證件照片',
        '家族图片': '家族圖片',
        '手持证件背面照': '手持證件背面照',
        '请输入抽成比例': '請輸入抽成比例',
        '请输入家族简介': '請輸入家族簡介',
        '提交申请': '提交申請',
        '等待上麦': '等待上麥',
        '聊天室': '聊天室',
        '上麦互动': '上麥互動',
       '申请上麦': '申請上麥',
        '取消排队': '取消排隊',
        '您当前的顺比特': '您當前的順比特',
         '当前上麦申请': '當前上麥申請',
        '上麦': '上麥',
       '上麦成功': '上麥成功',
        '上麦被拒绝': '上麥被拒絕',
        '控麦': '控麥',
       '下麦': '下麥',
        '闭麦': '閉麥',
        '禁麦': '禁麥',
        '取消禁麦': '取消禁麥',
         '开麦': '開麥',
        '您已被下麦': '您已被下麥',
        '已设定为静音': '已設定為靜音',
        '已取消静音': '已取消靜音',
        '退出直播间': '退出直播間',
        '聊天室最小化': '聊天室最小化',
        '主持': '主持',
        '全麦': '全麥',
        '一麦': '一麥',
        '二麦': '二麥',
        '三麦': '三麥',
        '四麦': '四麥',
        '五麦': '五麥',
        '六麦': '六麥',
        '七麦': '七麥',
        '八麦': '八麥',
        '直播间开启期间不可使用该操作': '直播間開啟期間不可使用該操作',
        '语音直播': '語音直播',
        '请完善证件信息': '請完善證件資訊',
        '请选择送的人': '請選擇送的人',
        '美颜': '美顏',
        '支付成功': '支付成功',
        '支付失败': '支付失敗',
        '取消支付': '取消支付',
        '创建家族': '創建家族',
        '基本信息': '基本資訊',
        '上传身份信息': '上傳身份資訊',
        '点击跳转协力厂商应用': '點擊跳轉協力廠商應用',
        '您还未登录': '您還未登錄',
        '登入账号查看精彩内容': '登入帳號查看精彩內容',
        '分享': '分享',
         '分享赚佣金': '分享賺傭金',
        '分享到动态': '分享到動態',
       '分享给好友': '分享給好友',
        '分享中': '分享中',
        '分享佣金': '分享傭金',
        '立即充值': '立即充值',
        '首冲豪华大礼包': '首沖豪華大禮包',
        '广告': '廣告',
        '请选择支付方式': '請選擇支付方式',
        '作者': '作者',
        '被跟买得': '被跟買得',
        '分享商品': '分享商品',
         '确定删除所选收藏？': '確定删除所選收藏？',

         '服务协定和隐私政策': '服務協定和隱私政策',
        '请您务必仔细阅读，充分理解服务协定和隐私政策各条款，包括但不限于为了向您提供实时通讯，内容分享等服务，我们需要收集您设备信息和个人信息，您可以在设定中查看，管理您的授权。 您可阅读《隐私政策》和《服务协议》了解详细信息，如您同意，请点击同意接受我们的服务。':'請您務必仔細閱讀，充分理解服務協定和隱私政策各條款，包括但不限於為了向您提供即時通訊，內容分享等服務，我們需要收集您設備資訊和個人資訊，您可以在設定中查看，管理您的授權。 您可閱讀《隱私政策》和《服務協議》瞭解詳細資訊，如您同意，請點擊同意接受我們的服務。',
        '登入即代表同意《隐私政策》和《服务协议》': '登入即代表同意《隱私政策》和《服務協議》',
        '《隐私政策》': '《隱私政策》',
        '《服务协议》': '《服務協議》',
    
        '\@好友': '\@好友',
        '你已经@过他了': '你已經@過他了',
        '已经@了相同昵称的人': '已經@了相同昵稱的人',
        '粉丝': '粉絲',
         '赞': '贊',
        '\@我的': '\@我的',
         '评论': '評論',
        '赞了您的作品': '贊了您的作品',
         '充值奖励': '充值獎勵',
        '直播明细': '直播明細',
        '查看我的明细': '查看我的明細',
       '还没有聊天室开启喔~': '還沒有聊天室開啟喔~',
        '评论了您的作品': '評論了您的作品',
        '@了你': '\@了你',
        '关注了你': '關注了你',
        '赞了您的评论':'贊了您的評論',
        '回复了您的评论': '回復了您的評論',
        '清空': '清空',
        '首冲机会已用完': '首沖機會已用完',
        '确定清空所有中奖记录？': '確定清空所有中獎記錄？',
        '请选择封禁时间': '請選擇封禁時間',
        '知道了': '知道了',
        '正在直播': '正在直播',
        '全部分类': '全部分類',
        '直播购': '直播購',
        '官方直营·正牌保障': '官方直營·正牌保障',
        '认证': '認證',
         '前去认证': '前去認證',
         '我的服务': '我的服務',
         '更多服务': '更多服務',
         '续费会员': '續費會員',
         '到期时间': '到期時間',
         '会员专享礼包来了': '會員專享禮包來了',
        '获赞': '獲贊',
        '更换': '更換',
        '去关闭': '去關閉',
        '修改密码': '修改密碼',
         '青少年模式': '青少年模式',
        '青少年模式未开启': '青少年模式未開啟',
        '青少年模式已开启': '青少年模式已開啟',
        '开启青少年模式需先设定独立密码': '開啟青少年模式，需先設定獨立密碼',
        '无法进行充值、打赏等操作': '無法進行充值、打賞等操作',
       '自动开启时间锁每天使用时长不超过40分钟每日晚22时至次日6时期间无法使用': '自動開啟時間鎖，每天使用時長不超過40分鐘，每日晚22時至次日6時期間無法使用',
        '开启青少年模式': '開啟青少年模式',
        '关闭青少年模式': '關閉青少年模式',
         '设置密码': '設置密碼',
        '请设定新密码': '請設定新密碼',
        '输入密码': '輸入密碼',
        '密码设定成功请记住您设定的密码': '密碼設定成功，請記住您設定的密碼',
         '请输入当前密码': '請輸入當前密碼',
       '请输入新的密码': '請輸入新的密碼',
       '请确定新的密码': '請確定新的密碼',
        '进入青少年模式': '進入青少年模式',
       '青少年模式下暂无商品信息': '青少年模式下暫無商品資訊',
        '青少年模式下不支持该功能': '青少年模式下不支持該功能',
         '未成年人禁止充值消费': '未成年人禁止充值消費',
         '重新设定': '重新設定',
        '确定修改': '確定修改',
         '高德地图': '高德地圖',
         '百度地图': '百度地圖',
         '腾讯地图': '騰訊地圖',
        '你没有安装导航软件': '你沒有安裝導航軟件',
        '请开启通知': '請開啟通知',
        '选择直播内容': '選擇直播內容',
        '购物车': '購物車',
        '在线观众': '線上觀眾',
        '开启定位服务': '開啟定位服務',
        '开启定位提示': '為了向您推薦附近直播和視頻，幫助您在使用相關服務時展示定位，開啟定位，發現身邊生活',
        '设置': '設定',
        '人在看': '人在看',
        '表情': '表情',
        '进入全功能模式': '進入全功能模式',
        '不同意': '不同意',
        '同意': '同意',
        '不同意并进入基本功能模式': '不同意並進入基本功能模式',
        '什么是基本功能模式': '什麼是基本功能模式',
        '星球探宝': '星球探寶',
        '幸运大转盘': '幸運大轉盤',
        '冥王星': '冥王星',
        '天王星': '天王星',
        '海王星': '海王星',
        '规则说明': '規則說明',
        '中奖记录': '中獎記錄',
        '暂无抽奖记录': '您還沒有記錄哦',
        '您还没有中奖记录': '您還沒有中獎記錄哦',
        '暂无抽奖排行榜': '暫無抽獎排行榜',
        '恭喜获得': '恭喜獲得',
        '未获得': '未獲得',
        '未获得提示': '很遺憾\n大獎與您擦肩而過',
        '获得礼物提示': '獲得的禮物將存放在禮物背包中',
        '排名': '排名',
        '用户信息': '用戶資訊',
        '奖品累计价值': '獎品累計價值',
        '个': '個',
        '玩法介绍': '玩法介紹',
        '参与记录': '參與記錄',
        '跳过动画': '跳過動畫',
        '单击': '單擊',
        '十连击': '10連擊',
        '百连击': '100連擊',
        '手游直播': '手遊直播',
        '开始录屏': '開始錄屏',
        '用户连麦': '用戶連麥',
        '私信对话平台已关闭暂时无法使用': '私信對話平臺已關閉，暫時無法使用',
        'token已过期': 'token已過期，請重新登入',
        '直播类型': '直播類型',
        '语音聊天室': '語音聊天室',
         '视频聊天室': '視頻聊天室',
    
         '首充礼包': '首充禮包',
         '礼包': '禮包',
         '首充豪华大礼包快来充值领取奖励吧': '首充豪華大禮包,快來充值領取獎勵吧',
        '充值后赠送钻石': '充值后贈送鑽石',
        '充值后赠送钻石和礼物': '充值后贈送鑽石和禮物',
        '充值后赠送钻石和VIP': '充值后贈送鑽石和VIP',
    
         '贴纸': '貼紙',
        '美颜': '美顏',
        '特效': '特效',
        '哈哈镜': '哈哈鏡',
        '基础贴纸': '基礎貼紙',
        '高级贴纸': '高級貼紙',
        '基础面具': '基礎面具',
        '高级面具': '高級面具',
        '贴纸下载失败': '貼紙下載失敗',
        '美颜': '美顏',
        '美型': '美型',
        '一键美颜': '一鍵美顏',
        '滤镜': '濾鏡',
        '水印': '浮水印',
        '动作': '動作',
        '美妆': '美妝',
         '原图': '原圖',
        '美白': '美白',
         '磨皮': '磨皮',
         '红润': '紅潤',
        '亮度': '亮度',
        '大眼': '大眼',
        '眉毛': '眉毛',
         '眼距': '眼距',
         '眼角': '眼角',
         '瘦脸': '瘦臉',
         '嘴型': '嘴型',
        '瘦鼻': '瘦鼻',
        '下巴': '下巴',
        '额头': '額頭',
        '长鼻': '長鼻',
         '削脸': '削臉',
         '开眼角': '開眼角',
        '标准': '標準',
        '优雅': '優雅',
        '精致': '精緻',
        '可爱': '可愛',
        '自然': '自然',
        '网红': '網紅',
        '脱俗': '脫俗',
        '高雅': '高雅',
         '无': '無',
       '浪漫': '浪漫',
        '清新': '清新',
         '唯美': '唯美',
        '粉嫩': '粉嫩',
        '怀旧': '懷舊',
         '清凉': '清凉',
        '蓝调': '藍調',
        '日系': '日系',
         '都市': '都市',
         '初恋': '初戀',
         '初心': '初心',
        '单色': '單色',
        '反差色': '反差色',
        '琥珀': '琥珀',
        '美味': '美味',
         '蜜桃粉': '蜜桃粉',
        '奶茶': '奶茶',
        '拍立得': '拍立得',
         '乌托邦': '烏托邦',
        '西柚': '西柚',
        '日杂': '日雜',
        '黑猫': '黑猫',
         '黑白': '黑白',
        '布鲁克林': '布魯克林',
        '平静': '平靜',
        '冷酷': '冷酷',
        '凯文': '凱文',
         '恋爱': '戀愛',
         '简报': '簡報',
        '灵魂出窍': '靈魂出竅',
         '抖动': '抖動',
        '闪白': '閃白',
        '毛刺': '毛刺',
        '幻觉': '幻覺',
        '马赛克1': '馬賽克1',
        '马赛克2': '馬賽克2',
        '马赛克3': '馬賽克3',
        '马赛克5': '馬賽克4',
         '原图': '原圖',
         '抬头': '抬頭',
       '张嘴': '張嘴',
       '眨眼': '眨眼',
       '外星人': '外星人',
       '梨梨脸': '梨梨臉',
       '瘦瘦脸': '瘦瘦臉',
       '镜像脸': '鏡像臉',
       '片段脸': '片段臉',
       '水面倒影': '水面倒影',
       '螺旋镜面': '螺旋鏡面',
       '鱼眼相机': '魚眼相機',
       '左右镜面': '左右鏡面',
       '原图': '原圖',
       '睫毛': '睫毛',
       '唇彩': '唇彩',
       '腮红': '腮紅',
       '眼影': '眼影',
       '眼线': '眼線',
        beauty_none: '',
       '请抬头': '請抬頭',
       '请张嘴': '請張嘴',
       '请眨眼': '請眨眼',
       '贴纸下载中请稍后': '貼紙下載中，請稍後',
    
    
    
        '智勇三张': '智勇三張',
        '幸运转盘': '幸運轉盤',
        '选择游戏': '選擇遊戲',
        '即将开始请稍后…': '即將開始，請稍後…',
        '赢了哦': '贏了哦',
        '请等待游戏结束': '請等待遊戲結束',
        '万': '萬',
        '大乔x2': '大喬x2',
        '貂蝉x2': '貂蟬x2',
        '小乔x2': '小喬x2',
        '开始支持': '開始支持',
        '揭晓结果': '揭曉結果',
        '胜败乃兵家常事': '勝敗乃兵家常事',
    
        '我点亮了': '我點亮了',
        '被踢出房间': '被踢出房間',
        '被永久禁言': '被永久禁言',
        '被本场禁言': '被本場禁言',
        '被设为管理员': '被設為管理員',
        '被取消管理员': '被取消管理員',
        '在直播间发红包啦快去抢哦': '在直播間發紅包啦！ 快去搶哦～',
        '欢迎来到直播间': '歡迎來到直播間,我們倡導綠色直播。直播內容和封面有違法違規、色情低俗、抽煙喝酒、誘導欺詐、聚眾鬧事等行為帳號會被封鎖,網警24小時在線巡查哦！',
        '主播离开一下': '主播離開一下，精彩不中斷，不要走開哦',
        '主播回来了': '主播回來了！',
        '关注了主播': '關注了主播',
    
        '收藏': '收藏',
        '热门': '熱門',
        '社区': '社群',
        '游戏': '遊戲',
        '视频': '影片',
        '终极会员': '終極會員',
        '启用': '啟用',
        '充值': '儲值',
        '奖励': '獎勵',
        '收入': '收入',
        '提现': '提領現金',
        '钱包':'錢包',
        '我的钱包': '我的錢包',
        '每日任务':'每日任務',
        '因汇率波动充值金额可能会存在变化 请以支付时金额为准':'因匯率波動充值金額可能會存在變化 請以支付時金額為准',
        '看视频20秒得现金打款': '觀看影片20秒即可獲得現金',
        '欢迎登录': '很高興見到您！',
        '我的现金': '我的現金',
        '个人中心': '個人中心',
        '消息': '訊息',
        '红包已经达到今日上限': '紅包已達到今日上限',
    
        '总数': '總數量',
        '首充礼包': '首存禮包',
        '快来充值领奖励吧': '快來儲值領取獎勵',
        '请联系管理员充值': '請聯絡管理員充值',
        '现金': '現金:',
        '功能暂未上线': '該功能尚未上線，敬請期待',
        '电话区号': '+886',
    
        '倍速': '速度',
        '两倍快进中': '2x快轉中',
    
        '切换倍速提示': '切換至{}倍速播放',
        '验证码': '驗證碼',
    
        '同意并登录': '同意並登入',
        '直播中': '直播中',
        '确定取消关注': '確認取消追蹤',
    
        '取消关注': '取消追蹤',
        '继续关注': '繼續追蹤',

        '领取': '領取',
        '欢迎来到LuckyLive': '歡迎來到 Lucky Live',
        '你可以在这里发表视频': '您可以在這裡發布視頻',
        '看视频可以获得金币': '觀看影片有金幣獎勵',
        '您可以在这里开始直播': '您可以在這裡開始直播',
        '您可以在这里提取现金': '您可以在這裡提取現金',
        '知道了': '知道了',

        '普通': '普通',
        '热门标签': '熱門',
        '幸运标签': '幸運',
        '守护标签': '守護',
        '钻石余额不足充值后才可继续赠送礼物': '鑽石餘額不足 儲值後才可繼續送禮物',
        '去充值': '去儲值',
        '新消息': '新消息',
        '刚刚': '剛剛',
        '分钟前': '分鐘前',
        '昨天': '昨天',

        '周一': '週一',
        '周二': '週二',
        '周三': '週三',
        '周四': '週四',
        '周五': '週五',
        '周六': '週六',
        '周日': '週日',

        '上周一': '上週一',
        '上周二': '上週二',
        '上周三': '上週三',
        '上周四': '上週四',
        '上周五': '上週五',
        '上周六': '上週六',
        '上周日': '上週日',

        '钻石': '鑽石',

        '支付': '支付',
        '支付方式': '支付方式',
        '选择支付方式': '選擇付款方式',
        '订单号': '訂單編號:',
        '已支付': '已支付',
        '订单请求中': '正在請求訂單，請稍候...',
        '订单确认中': '訂單確認，請稍候...',
        '返回': '後退',
        '支付成功': '付款成功',
        '支付失败': '支付失敗',
        '排行榜': '排行榜',
        '收益榜': '收益榜',
        '贡献榜': '貢獻榜',
        
        '下载APP': '下載 Lucky Live App',
        '请下载Lucky Live APP':'請下載Lucky Live App',
        '条':'條',
        '私信给': '私信給',
        '发送': '發送',

        '钻石记录':'鑽石記錄',
        '金币记录':'金幣記錄',
        '赠送礼物':'贈送禮物',
        '收到礼物':'收到禮物',
        '游戏结算':'遊戲結算',
        '后台充值':'後臺充值',
        '提款费':'提款費',
        '手续费':'手续费',
        '暂无数据':'暫無數據',
        '提现钻石数':'提现钻石数',
        '可转化为':'可轉化為',
        '提款':'提款',
        '单次提现范围':'單次提現範圍',
        '钻石每天可提现':'鑽石每天可提現',
        '次':'次',
        '提款规则':'提款規則',
        '进行中':'進行中',
        '成功':'成功',
        '账户':'賬戶',
        '查看原因':'查看原因',
        '提款方式':'提款管道',
        '选择到账银行卡': '選擇到賬銀行卡',
        '请注意各银行到账时间': '請注意各銀行到賬時間',
        '使用新卡提现': '使用新卡提現',
        '编辑资料':'編輯資料',
        '男':'男',
        '女':'女',
        '修改昵称':'修改昵稱',
        '保存':'保存',
        '修改签名':'修改簽名',
        '记得填写名字哦':'記得填寫名字哦',
        '这家伙很懒，什么都没留下':'這傢伙很懶，什麼都沒留下',
        '是否保存修改':'是否保存修改',
        '名字不能为空':'名字不能為空',
        '是否退出登录':'是否登出',
        '退出登录':'登出',
        '很高兴认识你':'很高興認識你',
        '英文':'英文',
        '越南语':'越南語',
        '繁体中文':'繁體中文',

        '总金额':'總金額',
        '全部':'全部',
        '可提现钻石':'可提現鑽石',
        '原生APP': '原生APP',
        '桌面快捷APP': '案頭快捷APP',

        '公会管理': '公会管理',
        '公会编号': '公会编号',
        '公会主播': '公会主播',
        '详细': '详细',
        '旗下主播': '旗下主播',
        '旗下主播收益': '旗下主播收益',
        '当月活跃主播': '当月活跃主播',
        '公会分成收益': '公会分成收益',
        '本月分成比例': '本月分成比例',
        '本月总收益': '本月总收益',
        '公会主播收益': '公会主播收益',
        '当月收益': '当月收益',
        '昨日收益': '昨日收益',
        '平台通知': '平台通知',
        '违规记录': '违规记录',
        '分成比例': '分成比例',
        '钻石分成比例': '钻石分成比例',
        '金币分成比例': '金币分成比例',
        '钻石礼物分成比例': '钻石礼物分成比例',
        '金币礼物分成比例': '金币礼物分成比例',
        '请输入分成比例': '请输入分成比例',
        '总收益': '总收益',
        '场次': '场次',
        '钻石礼物': '钻石礼物',
        '金币礼物': '金币礼物',
        '其他': '其他',
        '主播分成': '主播分成',
        '结算金额': '结算金额',
        '累计收益': '累计收益',
        '钻石收益': '钻石收益',
        '直播时长': '直播时长',
        '有效直播天数': '有效直播天数',
        '导出': '导出',
        '筛选': '筛选',
        '选择筛选方式': '选择筛选方式',
        '主播': '主播',
        '昵称或id号': '昵称或id号',
        '主播等级': '主播等级',
        '时间范围': '时间范围',
        '取消': '取消',
        '确定': '确定',
        '导出成功': '导出成功',
        '在浏览器中打开链接，即可下载': '在浏览器中打开链接，即可下载',
        '复制链接': '复制链接',
        '主播详情': '主播详情',
        '即：用户赠送价值100钻石的礼物，主播可获得100*50%=50钻石': '即：用户赠送价值100钻石的礼物，主播可获得100*50%=50钻石',
        '即：用户赠送价值1000金币的礼物，主播可获得1000*50%=500金币，关播后统一兑换为钻石': '即：用户赠送价值1000金币的礼物，主播可获得1000*50%=500金币，关播后统一兑换为钻石',
        '个人信息': '个人信息',
        '姓名': '姓名',
        '国家/地区': '国家/地区',
        '城市': '城市',
        '手机号': '手机号',
        '直播信息': '直播信息',
        '累计直播时长': '累计直播时长',
        '签约信息': '签约信息',
        '签约时间': '签约时间',
        '开播时间': '开播时间',
        '查看直播记录': '查看直播记录',
        '点击查看': '点击查看',
        '警告': '警告',
        '封禁7天': '封禁7天',
        '登录用户不一致，请重新登录':'登录用户不一致，请重新登录',

        '任务中心': '任務中心',
        '新增一个视频': '新增一個視頻',
        '观看视频': '觀看視頻',
        '轻松赚金币，快速提现': '輕鬆賺金幣，快速提現',
        '开播10分钟': '開播10分鐘',
        '观看直播10分钟': '觀看直播10分鐘',
        '关注主播': '關注1個房間',
        '送1份礼物': '送1份禮物',
        '发布1条动态': '發佈1條動態',
        '每日0点重置，所有时间均以UTC+07:00为准':'每日0點重置，所有時間均以UTC+07:00為准',
        '本活动与苹果公司无关':'本活動與蘋果公司無關',
        '观看视频，快速提现':'觀看視頻，快速提現',

        '直播收益': '直播收益',
        '公会管理': '公会管理',
        '今日开播时长（仅统计大于10min的场次）': '今日开播时长（仅统计大于10min的场次）',
        '场统计': '场统计',
        '日统计': '日統計',
        '月统计': '月統計',
        '日期': '日期',
        '时长': '時長',
        '开播时间':'开播时间',
        '关播时间':'关播时间',
        '今日收益': '今日收益',
        '本月收益': '本月收益',
        '本月开播时长': '本月開播時長',
        '在线天数': '線上天數',
        '收益': '收益',
        '上拉加载更多...': '上拉加載更多...',
        '公会管理后台': 'Guild management backend',
        // '今日已看满10分钟，明天再来':'今日已看满10分钟，明天再来',
        '今日已看满':'今日已看满',
        '分钟，':'分钟，',
        '明天再来':'明天再来',
        '点击加载更多':'点击加载更多',

        '最多可添加':'最多可添加',
        '个提现账号':'个提现账号',

        '场次：开播时间~关播时间':'场次：开播时间~关播时间',
        '钻石礼物：本场直播收到钻石礼物分成收益':'钻石礼物：本场直播收到钻石礼物分成收益',
        '金币礼物：本场直播收到金币礼物分成收益':'金币礼物：本场直播收到金币礼物分成收益', 
        '其他：累计本场直播获得的游戏、门票或计时分成收益，总收益（钻石）=钻石礼物+金币礼物/100+其他':'其他：累计本场直播获得的游戏、门票或计时分成收益， 总收益（钻石）=钻石礼物+金币礼物/100+其他', 
        '注意：收到的金币在结束直播时统一兑换为钻石':'注意：收到的金币在结束直播时统一兑换为钻石',
        '关闭':'关闭',

        '充值方式':'充值方式',
        '确认':'确认',

        '订单确认中，请稍等...':'订单确认中，请稍等...',
        '请重新登录':'请重新登录',

        '请在APP发布视频':'请在APP发布视频',
        '请在APP开启直播':'请在APP开启直播',
        
        '请绑定提款方式': '请绑定提款方式',
        '银行名称不能为空': '银行名称不能为空',
        '账户名称不能为空': '账户名称不能为空',
        '验证码不能为空': '验证码不能为空',
        '请输入钻石数量': '请输入钻石数量',
        '提现失败': '提现失败',
        '正在安装中...':'正在安装中...',
}
