export default {
    'Lucky Live':'Lucky Live',
    '下拉刷新':'Pull down to refresh',
    '刷新中':'Refreshing',
    '加载中':'Loading',
    '发布后刷新':'Refresh after release',
    '刷新完成':'Refresh completed',
    '刷新失败':'Refresh failed',
    '释放即可刷新...':'Release to refresh',
    '加载中...':'Loading',
    '最近更新':'Last update',
    '上拉加载更多':'Pull up to load more',
    '发布加载':'Release to load',
    '加载中':'Loading',
    '刷新中':'Refreshing',
    '加载完成':'Loading completed',
    '加载失败':'Loading failed',
    '全部加载':'All loaded',
    '暂时没有更多了':'There are currently no more available',
    '屏蔽':'Block',
    '解除屏蔽':'Unblock',
    '已拉黑':'Blacked',
    '已解除黑名单':'The blacklist has been lifted',
    '登录已满':'Signed in for',
    '连续登录可额外获得奖励':'Sign in continuously to receive additional rewards',
    '天':'Day',
    '美颜初始化异常，无法使用':'Beauty initialization exception, cannot be used',
    '提现账户':'Withdrawal account',
    '您尚未设置提现账户':'You have not set up a withdrawal account yet',
    '添加提现账户':'Add withdrawal account',
    '账户类型':'Account type',

    '银行名称':'Bank Name',
    '账户名称':'Name of account',
    '请输入账户名称':'Please enter the account name',
    '账户号码':'Account No',
    '请输入账户号码':'Please enter your account number',
    '手机号码':'Phone number',
    '请输入手机号码':'Please enter phone number',
    '验证码':'Verification code',
    '请输入验证码':'Please enter the verification code',
    '确定':'Confirm',
    '添加银行卡': 'Add bank card',
    '请提供正确的银行信息以避免提款失败': 'Please provide correct bank information to avoid withdrawal failure',
    '最小可提现为':'The minimum withdrawable amount is ',
    '最大可提现为':'The maximum withdrawable amount is ',
    '最多可提现为':'The maximum withdrawal amount is ',
    '钻石':'diamond',
    '提现方式':'Withdrawal method',

    '请输入持卡人姓名':'Please enter the cardholder’s name',
    '请输入银行卡账号':'Please enter the bank card account number',
    '请输入银行名称':'Please enter the bank name',
    '请输入微信账户':'Please enter your WeChat account',
    '请输入支付宝账户':'Please enter Alipay account',
    '请输入支付宝账户名':'Please enter Alipay account name',
    '支付宝':'Alipay',
    '微信':'WeChat',
    '银行卡':'Bank card',
    '是否删除该账户？':'Do you want to delete this account?',
    '提现记录':'Withdrawals record',
    '赠礼':'Gift',
    '账户余额':'Account balance',
    '支付宝':'Alipay',
    '微信支付':'WeChat payment',
    '充值成功':'Recharge successful',
    '充值失败':'Recharge failed',
    '未安装支付宝':'Alipay is not installed',
    '未安装微信':'WeChat is not installed',
    '未安装QQ':'QQ is not installed',
    '未配置支付宝':'Alipay is not configured',
    '未配置微信':'WeChat is not configured',
    '未配置Paypal':'Paypal is not configured',
    '复制链接':'Copy link',
    '复制成功':'Copy successfully',
    '编辑信息':'Edit information',
    '头像':'Avatar',
    '昵称':'Nickname',
    '修改用户名':'Change username',
    '修改签名':'Modify signature',
    '签名':'Sign',
    '生日':'Birthday',
    '性别':'Gender',
    '所在地':'Location',
    '我的主播印象':'My anchor impression',
    '设置头像成功':'Set avatar successfully',
    '留言':'Leave something behind',
    '请输入昵称':'Please enter a nickname',
    '最多可输入20个字符':'You can enter up to 20 characters',
    '昵称最多可输入8个字符':'Nickname can be up to 8 characters long',
    '请输入昵称':'Please enter a nickname',
    '请输入签名':'Please enter signature',
    '请选择正确的日期':'Please select the correct date',
    '超出字数限制':'Word limit exceeded',
    '最新':'Latest',
    '关注':'Follows',
    '已关注':'Followed',

    '您还没有关注任何人':'You are not following anyone yet',
    '赶紧关注你感兴趣的人':'Hurry up and follow the people you are interested in',
    'TA还没有关注任何人':'TA has not followed anyone yet',
    '粉丝': 'fans',
    '获赞': 'got',
    '你还没有粉丝':'You don\'t have fans yet',
    '完善个人资料可以让更多人关注你':'Improving your personal information will allow more people to pay attention to you',
    'TA还没有粉丝':'TA doesn’t have any fans yet',
    '忘记密码':'Forgot password',
    '立即查找':'Find now',
    '选择守护时长':'Choose guard duration',
    '选择特权':'Select privilege',
    '立即开通':'Open now',
    '开通守护':'Activate guard',
    '续订守护':'Renewal guard',
    '我的':'My',
    '守护':'Guard',
    '本周贡献':'Contribution this week:',
    '成为他的第一个守护者':'Be his first guardian',
    '快去为你最喜欢的主播激活守护':'Go and activate a guard for your favorite anchor',
    '你现在是这位主播的月度守护者 \n 守护日期结束于':'You are the monthly guardian of the current anchor \n The guardianship date ends at',
    '你现在是这位主播的年度守护者 \n 守护日期结束于':'You are the annual guardian of the current anchor \n The guardianship date ends at',
    '年度守护专属礼物':'Annual guard exclusive gift',
    '你尚未守护':'You haven’t guarded yet',
    '你现在是这位主播的年度守护者 \n 无法激活7天/月度守护':'You are the annual guard of the current anchor \n Cannot activate 7-day/month guard',
    '你现在是这位主播的月度守护者，激活年度守护会覆盖你的月度守护时长，是否激活？':'You are the monthly guard of the current anchor. Activating annual guard will cover your monthly guard duration. Do you want to activate it?',
    '你将花费为主播激活':'You will spend to activate for the host',
    '7天守护':'7-day guard',
    '月度守护':'Month Guard',
    '年度守护':'Year Guard',
    '守护者名单':'Guardian list',
    '人守护了这位主播':'guarded the anchor',
    '首页':'Home',
    '“”也在，~点击查看他的故事':'"" is also in ,~Click to view his story',
    '他有个粉丝，快来围观！':'He has  fans, come and watch!',
    'Hi~我是主播，快来陪我聊天吧。':'Hi~ I am the anchor, come and chat with me.',
    '[图片]':'[image]',
    '[语音]':'[Voice]',
    '[位置]':'[location]',
    '[商品]':'[Goods]',
    '[表情符号]':'[emoji]',
    '消息':'Message',
    '忽略未读':'Ignore unread',
    '忽略未读消息':'Unread messages ignored',
    '暂无未读消息':'No unread messages yet',
    '消息发送失败':'Message sending failed',
    '频繁操作':'Frequent operations',
    '对方暂时拒绝接收你的消息':'The other party temporarily refuses to receive your message',
    '您尚未收到任何消息':'You haven’t received any message yet',
    '发送消息':'Send message',
    '图片':'Image',
    '拍照':'Take photo',
    '语音输入':'Voice input',
    '位置':'Location',
    '选择图片':'Choose image',
    '未找到本地图片':'Local image not found',
    '请选择图片':'Please choose an image',
    '搜索位置':'Search location',
    '未获取位置信息':'Location information not obtained',
    '未加载地图':'Map not loaded',
    '未获取地址':'Address not obtained',
    '获取位置失败':'Getting location failed',
    '点击关注可及时查看对方动态':'Click to follow and you can see the other party’s updates in time',
    '关注对方成功':'Follow the other party successfully',
    '长按发言':'Press and hold to speak',
    '取消长按结束':'Unpress end',
    '请发言™':'Please speak',
    '录音时间过短':'Recording time is too short',
    '向上滑动取消发送':'Swipe up to cancel sending',
    '取消发送':'Sending canceled',
    '系统消息':'System message',
    '无系统消息':'No system message',
    '聊天':'Chat',
    '主播印象':'Anchor impression',
    '添加':'Add',
    '最多选择三项':'Select up to three items',
    '请选择印象':'Please choose impression',
    '未修改印象':'Impression not modified',
    '请选择您对主播的印象':'Please select your impression of the host',
    '您收到的主播印象':'The anchor impression you received',
    '您尚未收到印象':'You have not received the impression yet',
    '取消拍照':'Cancel photo taking',
    '取消选择':'Cancel selection',
    '取消剪裁':'Cancel crop',
    '剪裁失败':'Crop failed',
    '取消录音':'Cancel recording',
    '您的登录状态无效，请重新登录！':'Your login status is invalid, please log in again!',
    '登录即表示同意':'Login means consent',
    '服务与隐私条款':'Service and Privacy Terms',
    '其他登录方式':'Other login methods',
    'Hi~欢迎体验系统':'Hi~Welcome to experience the system',
    '请输入手机号':'Please enter phone number',
    '请输入密码':'Please enter password',
    '登录中':'Logging in',
    '立即注册':'Sign up now',
    '忘记密码':'Forgot password',
    '请输入正确的手机号':'Please enter the correct mobile phone number',
    '授权登录':'Authorizing login',
    '登录成功':'Login successful',
    "看视频30秒得现金打款":'Watch the video for 30 seconds and make a cash call',
    '当天观看有效时长已达上限':'The viewing duration of the day has reached the upper limit',
    '手机号码不能为空':'Login successful',
    '国际区号不能为空':'The international area code cannot be empty',
    '验证码不能为空':'The verification code cannot be empty',
    '重复观看当前视频不计时':'Repeat the current video without timing',
    '当前视频已达有效观看时长':'The current video has reached its valid viewing duration',
    
    '授权失败':'Authorization failed',
    '取消授权':'Authorization cancellation',
    '请仔细阅读用户协议并核对':'Please read the user agreement carefully and check',

    '添加到桌面，快速访问':'Add to desktop for quick access',
    '添加':'Add',
    '安装教程':'Installation Tutorial',
    '点击“分享”图标':'Click on the \'Share\' icon',
    '点击“添加到主屏幕”选项':'Click on the \'Add to Home Screen\' option',
    '在页面上查找':'Search on the page',
    '添加到主屏幕':'Add to home screen',
    '标记':'mark',
    '点击“添加”按钮':'Click the \'Add\' button',
    '在主屏幕点击Lucky Live打开应用':'Click on Lucky Live on the home screen to open the app',

    '点击“更多”图标':'Click on the \'More\' icon',
    '点击“添加到桌面”选项':'Click on the \'Add to Desktop\' option',
    '资源嗅探':'Resource sniffing',
    '网页翻译':'Web Page Translation',
    '添加到桌面':'Add to desktop',
    '页面查找':'Page search',
    '保存离线网页':'Save offline webpage',
    '点击“确定”按钮':'Click the \'OK\' button',
    '编辑书签':'Edit Bookmarks',
    
    '直播':'Live',
    '主播':'Anchor',
    '开始直播':'Start live',
    '房间类型':'Room type',
    '分享到':'Share to',
    '左右滑动查看更多分享':'Swipe left or right for more shares',
    '直播封面':'Live cover',
    '更换封面':'Change cover',
    '直播标题':'Live title',
    '为直播撰写标题':'Write a title for the live',
    '频道':'Channel',
    '选择直播频道':'Select live channel',
    '注意选择适合自己的频道，直播过程中，若运营人员发现所选频道与直播内容不符，将会调整您的直播频道。':'Pay attention to choosing a channel that suits you. During the live broadcast, if the operator finds that the selected channel does not match the live broadcast content, your live broadcast channel will be adjusted.',
    '请设置房间密码':'Please set the room password',
    '请设置费用金额\n（直播结束时会显示收入）':'Please set the fee amount\n (the income will be displayed at the end of the live broadcast)',
    '请设置房间密码':'Please set the room password',
    '请设置费用金额':'Please set the fee amount',
    '费用金额':'Charge amount',
    '选择费用':'Choose charge',
    '是否结束直播？':'Do you want to end the live?',
    '美颜':'Beauty',
    '翻拍':'Flip',
    '闪播':'Flash',
    '伴奏':'Accompaniment',
    '分享':'Share',
    '游戏':'Game',
    '镜像':'Mirror',
    '您已开启镜像':'You have enabled mirroring',
    '您已关闭镜像':'You have turned off mirroring',
    '红包':'Red envelope',
    '链接麦':'Link mic',
    '断开麦':'Disconnect mic',
    '仅后置摄像头可开启闪播':'Only the rear camera can turn on the flash',
    '开启闪播失败':'Failed to open flash',
    '您已被禁言':'You have been banned',
    '直播已结束':'Live has ended',
    '返回首页':'Return to homepage',
    '收入':'Income',
    '观看人数':'Viewers',
    '关闭直播':'Close live',
    '说点什么':'Say something',
    '开启弹幕， /条':'Open barrage,  /item',
    '请输入房间密码':'Please enter the room password',
    '密码错误':'Wrong password',
    '播放失败':'Playback failed',
    '不支持视频格式':'Video format not supported',
    '视频地址包含中文无法播放':'The video address contains Chinese and cannot be played',
    '守护':'Guard',
    '我亮了':'I light up',
    '进入直播间':'entered the live room',
    '礼物':'Gift',
    '背包':'Backpack',
    '道具':'Props',
    '发送 ':'Send ',
    '发送':'Send',
    '送了1个':'sent 1',
    '发送':'send ',
    '送给':'Gift to',
    '礼物':'Gift',
    '连送':'Send',
    'x':'x',
    '连送x':'Send x',
    '靓':'Pretty',
    '送出':'Send',
    '收入':'Income',
    '城市未设置':'City not set',
    '礼物贡献列表':'Gift contribution list',
    '踢出':'Kick',
    '永久封禁':'Permanent ban',
    '本场封禁':'Banned for this session',
    '设为管理':'Set to manage',
    '取消管理':'Unmanage',
    '管理员列表':'Administrator list',
    '确定要取消  的管理员身份吗？':'Are you sure you want to cancel  is administrator status?',
    '是否解禁该用户':'Whether to unban this user',
    '是否解禁该用户':'Whether to unkick the user',
    '关闭直播':'Close live',
    '封禁直播间':'Ban live  room',
    '停用账号':'Disable account',
    '现任管理员':'Current administrator',
    '被踢出房间':'Kicked out of the room',
    '永久封禁':'Permanently banned',
    '被本站封禁':'Banned from this site',
    '设为管理员':'Set as administrator',
    '取消管理员':'Administrator canceled',
    '您已被踢出房间':'You have been kicked out of the room',
    '直播内容涉嫌违规':'The live content is suspected of violating regulations',
    '贡献列表':'Contribution list',
    '数据':'Data',
    '主播暂时离开，精彩不间断，别离开':'The anchor leaves for a moment, the excitement will not be interrupted, don’t leave',
    '主播回来了！':'The anchor is back!',
    '推送失败':'Push failed',
    '摄像头打开失败':'Camera failed to open',
    '麦克风打开失败':'Microphone failed to open',
    '目前没有主播开播':'There is currently no anchor broadcasting',
    '开始您的直播':'Start your live',
    '您关注的主播尚未开播':'The anchor you follow has not started broadcasting',
    '赶紧观看其他主播的直播':'Hurry up and watch the live broadcasts of other anchors',
    '赶紧浏览其他频道':'Hurry up and browse other channels',
    '暂无视频':'No video yet',
    '去发自己视频':'Go post a video of yourself',
    '附近没有主播':'There is no anchor nearby',
    '去首页看其他主播':'Go to the homepage to watch other anchors\'live broadcasts',
    '您还没有':'You haven\'t posted any news yet',
    '去分享你的感受':'Go and share your feelings',
    '暂无动态':'No news yet',
    '查看推荐页':'Check out the recommendations page',
    '他还没有发布任何内容':'He hasn\'t posted anything yet',
    '您还没有发布任何新闻':'You hasn\'t posted anything yet',
    '去看看其他分类的视频吧~':'Go check out the videos in other categories~',
    '您最近没有玩过直播':'You have not played a live broadcast recently',
    '去开播直播体验一下':'Go to the opening live broadcast to experience it',
    '他最近没有直播':'He hasn\'t broadcast live recently',
    '请选择直播内容':'Please select live broadcast content',
    '确定举报？':'Are you sure to report?',
    '链接麦':'Link mic',
    '结束麦':'End mic',
    '允许麦':'Allow mic',
    '无麦':'No mic',
    '对方刚开始直播，请等待':'The other party has just started live broadcast, please wait',
    '发起麦请求':'Initiate a mic request',
    '发起PK请求':'Initiate PK request',
    '退出麦':'Exit mic',
    '主播无法联系到':'The anchor cannot be reached',
    '对方未回应':'The other party does not respond',
    '主播已连接麦，请稍后重试':'The anchor is connected to the mic, please try again later',
    '对方主播正忙':'The other anchor is busy',
    '主播拒绝了你的连接麦请求':'The host has rejected your request to connect to the mic',
    '主播拒绝了你的连接麦请求':'The anchor has rejected your request to connect to the mic',
    '主播拒绝了你的PK请求':'The anchor rejected your PK request',
    '断麦':'Mic disconnected',
    '对方主播接受你的连接麦请求':'The other anchor accepted your request to connect to the mic',
    '对方正忙':'The other party is busy',
    '主播接受你的连接麦请求':'The anchor accepted your request to connect to the mic',
    '对方主播接受你的连接麦请求':'The other anchor accepted your request to connect to the mic',
    '主播接受你的PK请求':'The anchor accepted your PK request',
    '推流失败，已退出麦':'Failed to push the stream to the mic and has exited the mic',
    '连接麦请求已发送':'Mic connection request has been sent',
    'PK请求已发送':'PK request sent',
    '对方发起PK':'The other party initiates PK',
    '连接麦无法开启伴奏':'Cannot turn on accompaniment when connected to mic',
    '连接麦需要关闭背景音乐':'Background music needs to be turned off when connecting to mic',
    '直播录制':'Live record',
    '无标题':'Untitled',
    '有人观看':'People watched',
    '游戏状态 无法连接麦':'The game status cannot be connected to mic',
    '连接麦状态下无法玩游戏':'You cannot play the game if you are connected to the mic',
    '主播正忙':'The anchor is busy',
    '正在连接麦':'Mic link is currently in progress',
    '您已在PK中':'You are already in PK',
    '我们的':'Ours',
    '对方':'Other party',
    '余额不足，是否充值？':'The balance is insufficient, do you want to recharge?',
    '目前在线榜单':'Current online listings',
    '其他主播正在游戏中':'The other host is in the game',
    '邀请':'Invite',
    '已邀请':'Invited',
    '请输入主播昵称或ID':'Please enter the anchor’s nickname or ID',
    '当前没有主播':'There is currently no host',
    '未找到相关内容':'No relevant content found',
    'PK时间':'PK time',
    '惩罚时间':'Penalty time',
    '首页':'Home',
    '附近':'near',
    '动态':'Dynamic',
    '排行榜':'Ranking',
    '商城':'Mall',
    '矿场':'Mine',
    '普通房间':'Normal room',
    '付费房间':'Paid room',
    '限时房间':'Timed room',
    '密码房间':'Password room',
    '收益榜':'Revenue list',
    '贡献榜':'Contribution list',
    '每日':'Daily',
    '每周':'Weekly',
    '每月':'Monthly',
    '总计':'Total',
    '维护公告':'Maintenance Notice',
    '空缺等待':'Vacant waiting',
    '暂时空缺':'Temporarily vacant',
    '正在直播':'Live now',
    '发布视频':'Publish video',
    '请填写邀请码':'Please fill in the invitation code',
    '再次点击退出':'Click again to exit',
    '推荐主播':'Recommended anchor',
    '热门主播':'Popular anchor',
    '查看全部':'View all',
    '旧密码':'Old password',
    '请输入旧密码':'Please enter the old password',
    '新密码':'New password',
    '请填写新密码':'Please fill in the new password',
    '确认密码':'Confirm password',
    '确认新密码':'Confirm new password',
    '立即修改':'Modify now',
    '重置密码':'Reset password',
    'QQ':'QQ',
    'QQ空间':'QQ Zone',
    '微信':'WeChat',
    '朋友圈':'Friends Circle',
    'Facebook':'Facebook',
    'Twitter':'Twitter',
    '搜索歌曲':'Search for songs',
    '暂无歌曲可下载':'No songs to download yet',
    '歌曲下载地址无效':'Invalid song download address',
    '未找到歌词':'Lyrics not found',
    '未找到歌曲':'Song not found',
    '音乐':'Music',
    '音乐':'Music',
    '音量':'Volume',
    '没有音乐':'No music',
    '您尚未收藏任何音乐':'You haven\'t collected any music yet',
    '没有该音乐':'No such music',
    '我们来看看其他音乐类型':'Let’s check out other music types',
    '$':'$',
    '您已拒绝的权限，请前往设置进行修改':'You have denied the permission of , please go to the settings to modify it',
    '文件读写':'File reading and writing',
    '使用摄像头':'Use camera',
    '使用麦克风':'Use microphone',
    '使用位置':'Use location',
    '读取手机信息':'Read phone information',
    '读取应用列表':'Read application list',
    '您已拒绝显示悬浮窗的权限，请前往设置进行修改':'You have denied the permission to display floating windows, please go to the settings to modify it',
    '收入':'Income',
    '可用金额':'Amount available',
    '请选择提现账户':'Please select a withdrawal account',
    '输入需提现的金额':'Enter the amount of  to be withdrawn',
    '您输入的金额大于可提现金额':'The amount you entered is greater than the amount you can withdraw',
    '共计':'Total',
    '可提取':'Extractable',
    '输入数量':'Enter the number',
    '立即提现':'Withdraw immediately',
    '注册':'Register',
    '请输入手机号':'Please enter phone number',
    '验证码':'Verification code',
    '请填写密码':'Please fill in the password',
    '请确认密码':'Please confirm password',
    '注册登录':'Register and log in',
    '获取验证码':'CAPTCHA',
    '重新发送':'Resend',
    '找回':'Reget',
    '密码不一致':'Password inconsistent',
    '注册中':'Registering',
    '直播间红包':'Live room red envelope',
    '向当前直播间观众发红包':'Send red envelopes to viewers in the current live room',
    '幸运':'Lucky',
    '平均':'Average',
    '恭喜发财，好运连连':'Congratulations on getting rich and good luck',
    '红包':'Red envelopes ',
    '请输入金额':'Please enter the amount',
    '请输入数量':'Please enter the quantity',
    '共计个红包':'Total  red envelopes',
    '抢':'Rob',
    '派发即时红包':'Distributed an instant red envelope',
    '派发延时红包':'Distributed a delayed red envelope',
    '抢':'Rob',
    '查看领奖详情>':'View collection details>',
    '恭喜！':'Congratulations!',
    '已抢到人派发的个红包':'Grabbed  red envelope distributed by',
    '个红包':'Red envelope of',
    '已存入“我的”':'Deposited into "My "',
    '已到账个，共计个':'Received , total',
    '未抢到':'Not grabbed',
    '倒计时结束后方可抢到':'You can grab it after the countdown',
    '直播间红包发放中！快去抢吧~':'Red envelopes are distributed in the live room! Go and grab it~',
    '单笔金额':'Single amount',
    '一次性领取':'Lump sum',
    '推荐':'Recommended',
    '跳过':'Skip',
    '点击进入':'Click to enter',
    '为您推荐':'Recommended for you',
    '男性':'Male',
    '女性':'Female',
    '设置':'Set up',
    '清除':'Clearing',
    '清除缓存':'Cache cleared',
    '夜间模式':'Night mode',
    '音效':'Sound effects',
    '声音音效': 'Sound effects',
    '意见反馈': 'Feedback',
    '小窗模式':'Small window mode',
    '搜索':'Search',
    '搜索':'Search',
    '输入昵称或ID':'Enter the nickname or ID',
    '未找到相关内容':'No relevant content found',
    'QQ':'QQ',
    'QQ空间':'QQ Zone',
    '微信':'WeChat',
    '朋友圈':'Friends Circle',
    'facebook':'facebook',
    'twitter':'twitter',
    '分享成功':'Sharing successfully',
    '分享失败':'Sharing failed',
    '取消分享':'Share cancel',
    '版本更新':'Version update',
    '立即使用':'Use immediately',
    '尚未更新':'Not updated yet',
    '已是最新版本':'Already the latest version',
    '下载地址无效':'Invalid download address',
    '说点什么':'Say something',
    '视频下载成功':'Video download successfully',
    '视频下载失败':'Video download failed',
    '没有更多视频':'No more videos',
    '评论':'Comment',
    '无法为自己的评论点赞':'Cannot like your own comments',
    '无法回复自己':'Cannot reply to myself',
    '暂无评论，快来抢沙发':'No comments yet, come and grab the sofa',
    '回复':'Reply',
    '回复':'Reply to:',
    '展开更多回复':'Expand more replies',
    '收起':'Collapse',
    '翻转':'Flip',
    '闪现':'Flash',
    '摄像头打开失败':'Camera opening failed',
    '麦克风打开失败':'Microphone opening failed',
    '录制失败':'Recording failed',
    '稍等，画面还没出来':'Wait a moment, the screen hasn’t come out yet',
    '还有录制任务未完成':'There are still recording tasks that have not been completed',
    '传入视频路径为空':'The incoming video path is empty',
    '版本过低':'Version too low',
    '腾讯云认证失败':'Tencent Cloud authentication failed',
    '极慢':'Extremely slow',
    '慢':'Slow',
    '正常':'Normal',
    '快':'Fast',
    '极快':'Extremely fast',
    '视频预处理……':'Video preprocessing...',
    '视频预处理失败':'Video preprocessing failed',
    '取消视频预处理':'Video preprocessing cancellation',
    '处理中':'Processing',
    '异常状态，停止编辑':'Abnormal status, stop editing',
    '选择音乐':'Choose music',
    '搜索歌名':'Search song name',
    '热门歌曲':'Popular song',
    '我的收藏':'My collection',
    '滤镜':'Filter',
    '裁剪':'Crop',
    '特效':'Special effects',
    '截取所需音频片段':'Intercept the required audio clip',
    '原声':'Original sound',
    '请拖动两边滑块选择裁剪区域':'Please drag the sliders on both sides to select the cropping area',
    '添加视频说明':'Add video description',
    '确认发布':'Confirm release',
    '视频发布':'Video release',
    '视频生成中':'Video generating',
    '视频生成成功':'Video generated successfully',
    '生成视频失败':'Failed to generate video',
    '是否放弃发布该视频？':'Do you want to give up publishing this video?',
    '放弃':'Give up',
    '未找到本地视频':'Local video not found',
    '本地视频':'Local video',
    '视频时长不符合要求':'Video duration does not meet requirements',
    '生成视频封面图失败':'Failed to generate video cover image',
    '请添加视频说明':'Please add video description',
    '发布':'Publishing',
    '仅保存':'Save only',
    '仅发布':'Publish only',
    '保存发布':'Save and publish',
    '重新拍摄':'Reshoot',
    '退出':'Exit',
    '长按添加特效':'Long press to add special effects',
    '发布失败':'Publishing failed',
    '您还没有视频作品':'You have no video works yet',
    '赶紧拍摄上传':'Hurry up and shoot and upload',
    'TA还没有视频作品':'TA has no video works yet',
    '你喜欢的作品将放在这里':'The works you like will be placed here',
    '我的视频':'My video',
    '我的喜欢':'My like',
    '选择举报原因':'Select a reason for reporting',
    '请在描述框中描述更多详细信息（可选）':'Please describe more details in the description box (optional)',
    '提交':'Submit',
    '请选择举报原因':'Please select the reason for reporting',
    '举报成功':'Report successful',
    '已到达顶部':'It has reached the top',
    '确定删除最后一段视频？':'Are you sure you want to delete the last video?',
    '是否退出视频编辑':'Whether to exit video editing',
    '添加':'Add',
    '已添加':'Added',
    '接受':'Accept',
    '全部':'All',
    '相册':'Album',
    '取消':'Cancel',
    '拍照':'Photograph',
    '确定':'Sure',
    '请输入内容':'Please enter content',
    '选择':'Choose',
    '选择文件':'Choose file',
    '无法关注自己':'Cannot follow myself',
    '提示':'Hint',
    '下载':'Download',
    '删除':'Delete',
    '结束':'End',
    '热门':'Hot',
    '网络请求失败':'Network request failed',
    '请检查网络连接后重试':'Please check the network connection and try again',
    '加载中':'Loading',
    '位置':'Location',
    '没有更多数据':'No more data',
    '暂无数据':'No data',
    '附近':'Nearby',
    'W':'W',
    '请稍候':'Please wait',
    '私信':'Private letter',
    '举报':'Report',
    '重试':'Retry',
    '拒绝':'Reject',
    '保存':'Save',
    '发送':'Send',
    '抱歉':'Sorry',
    '视频':'Video',
    '人':'people',
    '个人':'Indivual',
    '数量':'Quantity',
    '下一个':'Next',
    '上传':'Upload',
    '我的直播间':'My live room',
    '我的房间':'My room',
    '管理员':'Administrator',
    '封禁用户':'Block user',
    '被封禁用户':'Banned user',
    '封禁用户列表':'Banned user list',
    '为直播间':'is live room',
    '解禁':'Unban',
    '封禁用户列表':'Block user list',
    '没有被封禁的用户':'No banned user',
    '尚未封禁用户':'No users have been blocked',
    '您还不是管理员':'You are not an administrator yet',
    '解禁':'Unblock',
    '赢取':'Win',
    '邀请码':'Invitation code',
    '恭喜获得x次':'Congratulations to  for getting x times',
    '幸运礼物说明':'Lucky gift description',
    '道具礼物说明':'Prop gift description',
    '当前奖池等级':'Current prize pool level',
    '当前奖池金额':'Current prize pool amount',
    'Lv.':'Lv.',
    '发弹幕需达到等级':'To send barrages, you need to reach level',
    '发言需达到等级':'To speak, you need to reach level',
    '开启定位':'Open location',
    '稳固关闭':'Close firmly',
    '关闭定位，附近的人将看不到直播，直播间人数可能减少，确定关闭吗？':'Close positioning, the live will not be seen by people nearby, and the number of people in the live room may decrease. Are you sure to close it?',
    '火星':'Mars',
    '道具':'Props',
    '详情':'Details',
    '签名':'Signature',
    '个人信息':'Personal information',
    '选择支付方式':'Select payment method',
    '支出':'Expenditure',
    '收入':'income',
    '《用户充值协议》':'"User Recharge Agreement"',
    '阅读并同意':'Read and agree',
    '选择金额':'Select amount',
    '未选择支付方式':'No payment method selected',
    '我的':'My',
    '确认支付（）':'Confirm payment ()',
    '支付方式':'Payment method',
    '立即支付':'Pay immediately',
    '用户等级':'User level',
    '主播等级':'Anchor level',
    '连接已断开，请重新开始播报':'The connection has been disconnected, please restart the broadcast',
    '清空':'Empty',
    '游戏规则':'Game rules',
    '中奖记录':'Winning record',
    '转次':'Turn  times',
    '获得的礼物将存放在礼物背包中':'The gifts obtained will be stored in the gift backpack',
    '打开购物车':'Open shopping cart',
    '关闭购物车':'Close shopping cart',
    '淘宝商品':'Taobao products',
    '商城小程序':'Mall applet',
    '商品链接':'Product link',
    '商品名称（最多输入20个字符）':'Product name (Enter up to 20 characters)',
    '原价':'Original price',
    '现价':'Current price',
    '确认添加商品':'Confirm to add goods',
    '商品介绍（最多输入50个字符）':'Product introduction (Enter up to 50 characters)',
    '商品图片':'Product pictures',
    '浏览次数：次':'View count:  times',
    '（在小程序中输入商品对应ID，会自动获取商品信息）':'(Enter the corresponding ID of the product in the mini program, and the product information will be automatically obtained)',
    '商品名称':'Product name',
    '商品介绍':'Product introduction',
    '商品ID':'Goods ID',
    '获取商品信息':'Get product information',
    '查看商品详情':'View product details',
    '广告链接无效':'Invalid advertising link',
    '在售商品':'Goods on sale',
    '去看看':'Go and see',
    '暂无在售商品':'There are currently no products for sale',
    '全部商品':'All products',
    '添加商品':'Add goods',
    '移除':'Remove',
    '目前没有在售商品 \n点击右上角添加商品到购物车':'There are currently no products on sale \n Click the upper right corner to add products to the shopping cart',
    '请输入商品名称':'Please enter the product name',
    '您的店铺暂无商品 \n请先到店铺添加商品':'There are no products in your store \n Please go to the store to add products first',
    '在售商品':'Goods on sale',
    '去店铺购物':'Go shopping in the store',
    '推荐商品':'Recommended products',
    '去购买':'Go and buy',
    '已下架':'Removed',
    '相关商品':'Related goods',
    '查看商品详情':'View product details',
    '查看详情':'View details',
    '广告链接无效':'Invalid advertising link',
    '重新上架':'Relist',
    '删除后，该商品将不再在店铺展示 \n确定要删除该商品吗？':'After deletion, this product will no longer be displayed in the store \n Are you sure you want to delete this product?',
    '商品下架后，用户将无法购买该商品 \n确定要删除该商品吗？':'Users will not be able to purchase this product after it is removed from the shelves \n Are you sure you want to remove this product?',
    '请输入商品id':'Please enter the product id',
    '管理移除':'Manage removal',
    '已下架':'Removed',
    '显示':'Display',
    '全部图片':'All photos',
    '完成':'Done',
    '拍摄':'Take',
    '您最多可以选择张图片':'You can select at most  pictures',
    '发布更新':'Publish updates',
    '发布':'Release',
    '分享你的想法':'Share your thoughts',
    '位置':'Location',
    '预览':'Preview',
    '动态':'Dynamic',
    '位置':'Location',
    '不显示位置':'Do not show location',
    '正在录制':'Recording',
    '点击录制':'Click to record',
    '点击暂停':'Click to pause',
    '请先录制':'Please record first',
    '录制结束':'Recording ends',
    '使用视频':'Use video',
    '删除视频':'Delete video',
    '您确定要放弃当前编辑内容吗？':'Are you sure you want to abandon the current editing content?',
    '点赞':'Like',
    '待审核':'Pending review',
    '失败':'Failed',
    '说说你的想法…':'Tell me what you think',
    '动态详情':'Dynamic details',
    '暂无评论，快来抢沙发':'No comments yet, come and grab the sofa',
    '全部评论':'All comments',
    '\@TA':'\@TA',
    '在\中赠送了':'gave away in',
    '账号已被封禁':'Account has been banned',
    '封禁描述':'Ban description:',
    '封禁时长':'Block duration:',
    '了解':'Know',
    '视频类别':'Video category',
    '全部类别':'All categories',
    '选择视频类别':'Select video category',
    '请选择视频类别':'Please select the video category',
    '发送了个道具礼物':'Sent  prop gift',
    '下一个':'Next',
    '该房间为密码房间，请输入密码':'This room is a password room, please enter the password',
    '该房间为付费房间，您需要支付':'This room is a paid room, you need to pay ',
    '该房间为计时房间，每分钟需要支付':'This room is a timed room and requires   per minute',
    '已经是最后一个':'Already the last one',
    '重新拍摄':'Reshoot',
    '使用视频':'Use video',
    '云豹商店':'Clouded Leopard Shop',
    '浏览历史':'Browsing history',
    '我的地址':'My address',
    '账户余额':'Account balance',
    '我的订单':'My order',
    '查看所有订单':'View all orders',
    '待付款':'Pending payment',
    '待发货':'To be delivered',
    '待收货':'Awaiting receipt',
    '待评价':'Be evaluated',
    '退款':'Refund',
    '我要开店':'I want to open a store',
    '开小店赚钱':'Open a small shop to make money',
    '开店':'Open a store',
    '信息确认':'Information confirmation',
    '账户ID':'Account ID:',
    '姓名':'Name:',
    '身份证号码':'ID card number:',
    '1.请确认以上账户ID、姓名、身份证号码为您本人信息，如非您本人信息，请停止操作。':'1. Please confirm that the above account ID, name, and ID number are your own information. If it is not your own information, please stop operating.',
    '2.您同意授权本平台公司获取并保存账户ID、姓名、身份证号码等个人信息，用于身份认证及备案。':'2. You agree to authorize this platform company to obtain and save personal information such as account ID, name, and ID number for identity authentication and filing.',
    '3.您承诺通过本平台店铺开展的经营活动，属于法律法规规定不需要进行市场主体登记的经营活动。':'3. You promise that the business activities carried out through the store on this platform are business activities that do not require market entity registration according to laws and regulations.',
    '业务类别':'Business category',
    '请选择':'Please select',
 
    '运营商联系方式':'Operator contact information',
    '联系人':'Contact',
    '手机号码':'Mobile phone number',
    '地区':'Region',
    '详细地址':'Detailed address',
    '客服':'Customer service',
    '（联系方式留空）':'(Leave blank for contact information)',
    '客服电话':'Customer service phone number',
    '退货信息':'Return information',
    '（运营商信息留空）':'(Leave blank for operator information)',
    '收货人':'Consignee',
    '收货人手机号':'Consignee’s mobile phone number',
    '资质证书':'Qualification certificate',
    '营业执照':'Business license',
    '其他文件':'Other documents',
    '支付保证金':'Pay deposit',
    '提交':'Submit',
    '请慎重选择，主类目设置成功后不可更改':'Please choose carefully, the main category cannot be changed after it is successfully set',
    '选择主类目':'Select the main category',
    '业务类别设置':'Business category setting',
    '最多可选择三个主类目':'You can choose up to three main categories',
    '请选择业务类别':'Please select a business category',
    '已选':'Selected',
    '开店保证金':'Store opening deposit',
    '需支付金额':'Amount to be paid',
    '保证金说明':'Margin description',
    '1.保证金是平台从商家处暂时扣留的，用于约束商家':'1. The deposit is temporarily kept by the platform from the merchant to restrain the merchant ',
    '2.用户取消时，可以申请退还保证金。':'2. When the user cancels , he or she can apply for a refund of the deposit. ',
    '3.用户开店后，若出现欺骗消费者、售卖假冒伪劣产品等违反国家法律法规及平台规定的行为，平台有权强制关闭店铺，且保证金不予退还。':'3. After a user opens a store, if there is any behavior such as deceiving consumers, selling fake and shoddy products, etc. that violates national laws and regulations and platform regulations, the platform has the right to forcefully close the store and the deposit will not be refunded ',
    '4.平台对店铺押金保留最终解释权。':'4. The platform reserves the final right to interpret the store deposit. ',
    '确认支付':'Confirm payment',
    '押金已付':'Deposit paid',
    '请输入您的姓名':'Please enter your name',
    '请输入您的证件号码':'Please enter your ID number',
    '请输入经营者名称':'Please enter the name of the operator',
    '请输入经营者手机号码':'Please enter the operator’s mobile phone number',
    '请输入经营者所在地区':'Please enter the region where the operator is located',
    '请输入经营者详细地址':'Please enter the operator’s detailed address',
    '请设立营业执照':'Please set up a business license',
    '请先支付押金':'Please pay the deposit first',
    '信息审核中｜':'Information under review',
    '身份信息审核失败':'Identity information review failed',
    '重新认证':'Recertification',
    '审核结果将在3个工作日内出，请耐心等待':'The review results will be available within 3 working days, please wait patiently',
    '（卖家侧）':'(Seller side)',
    '暂无商品':'No product yet',
    '评分':'score',
    '账户余额（$）':'Account balance ($)',
    '累计收入（$）':'Cumulative income ($)',
    '订单管理':'Order management',
    '添加商品':'Adding goods',
    '商品管理':'Goods management',
    '地址管理':'Address management',
    '选择商品分类':'Select product category',
    '商品分类':'Product Category',
    '请选择':'Please select',
    '商品标题':'Product title',
    '商品关键词，15个字以内':'Key words for goods, within 15 words',
    '商品视频':'Product video',
    '商品图片':'Product picture',
    '商品详情':'Product details',
    '建议通过详细描述、规格、搭配、购买须知等方式描述商品细节，不超过300字':'It is recommended to describe the details of the product through detailed description, specifications, matching, purchase instructions, etc., no more than 300 words',
    '详情图片':'Details picture',
    '编辑规格':'Edit specifications',
    '最多15个字符':'Up to 15 characters',
    '规格':'Specifications',
    '库存（件）':'Inventory (pieces)',
    '最多可存9999999件':'Up to 9999999 pieces',
    '单价（$）':'Unit price ($)',
    '最低$1.00':'Minimum $1.00',
    '上传图片':'Upload pictures',
    '新增规格':'New specifications',
    '运费设置':'Freight settings',
    '包邮':'Free shipping',
    '提交评论':'Submit review',
    '删除规格':'Delete specification',
    '运费（$）':'Freight ($)',
    '请选择商品分类':'Please select the product category',
    '请输入商品标题':'Please enter the product title',
    '请输入商品详情':'Please enter product details',
    '请输入邮费':'Please enter the postage',
    '请输入规格名称':'Please enter the specification name',
    '请输入库存':'Please enter inventory',
    '请输入单价':'Please enter the unit price',
    '请设置商品图片':'Please set product pictures',
    '特价销售':'On sale',
    '评论':'Review',
    '已下架':'Removed',
    '价格及库存':'Price and inventory',
    '已下架':'Removed',
    '已售出件':'items sold',
    '您还没有任何相关产品':'You don\'t have any related products yet',
    '编辑':'Edit',
    '正在评论':'Under review',
    '上架':'On shelves',
    '预览商品':'Preview product',
    '商品详情':'Product details',
    '选择':'Select',
    '请选择规格':'Please select specifications',
    '服务':'Serve',
    '已付定金':'Deposit paid',
    '品质保证':'Quality assurance',
    '前往店铺':'Go to shop',
    '销量总计':'Total sales',
    '产品质量':'Product quality',
    '服务态度':'Service attitude',
    '物流服务':'Logistics services',
    '商品评价':'Goods evaluation',
    '商品详情':'Goods details',
    '暂无评论':'No reviews received yet',
    '暂无详情':'No details yet',
    '立即购买':'Buy now',
    '店铺':'Shop',
    '服务':'Service',
    '个人主页':'Personal homepage',
    '文件不存在':'File does not exist',
    '基本信息':'Basic information',
    '资质证书':'Qualification certificate',
    '查看资质证书':'View qualification certificate',
    '实名认证':'Real-name authentication',
    '已认证':'Authenticated',
    '店铺定金':'Store deposit',
    '已付款':'Paid',
    '下一步':'next step',
    '店铺详情':'Store details',
    '退货地址管理':'Return address management',
    '编辑收货地址':'Edit shipping address',
    '请输入您的联系电话':'Please enter your name',
    '请输入您的地区':'Please enter your contact number',
    '请输入详细地址':'Please enter your region',
    '添加收货地址':'Please enter the detailed address',
    '暂无收货地址':'Add shipping address',
    '我的地址':'No shipping address yet',
    '保存并使用':'My address',
    '姓名':'Save and use name',
    '联系电话':'Contact number',
    '设为默认地址':'Set as default address',
    '默认':'Default',
    '编辑收货地址':'Edit shipping address',
    '确定要删除？':'Are you sure you want to delete? ',
    '进入店铺':'Enter the store',
    '共件商品':'Total  items',
    '在售商品':'Items on sale',
    '件':'pieces',
    '管理':'Manage',
    '浏览记录':'Browsing history',
    '全选':'Select all',
    '暂无近期浏览记录':'No recent browsing history',
    '服务保障':'Service guarantee',
    '已支付定金':'Deposit has been paid',
    '商家已向平台缴纳保证金，用于在交易发生纠纷时维护买家权益':'The merchant has paid a deposit to the platform, which will be used to protect the buyer\'s rights when disputes arise in the transaction',
    '商家已向平台提交营业执照、牌照资质等相关资质证明。':'The merchant has submitted business license, licensing qualifications and other relevant qualification certificates to the platform. ',
    '立即购买':'Buy now',
    '库存':'Inventory:',
    '已选':'Selected:',
    '运费':'Freight:',
    '邮费':'Postage',
    '包邮':'Free shipping',
    '总计':'Total',
    '买家留言':'Buyer message',
    '建议留言前先与商家沟通确认':'It is recommended to communicate with the merchant for confirmation before leaving a message',
    '应付':'Payable:',
    '提交订单':'Submit order',
    '确认订单':'Confirm order',
    '规格':'Specification:',
 
    '手机号归属地': 'Mobile phone number ownership location',
    '请设置收货地址':'Please set the delivery address',
    '收银台':'Cashier',
    '付款':'payment',
    '我的订单':'My order',
    '全部':'All',
    '您暂无相关订单':'You have no related orders yet',
    '共件，共':'Total  items, total',
    '取消订单':'Cancel order',
    '付款':'Payment',
    '删除订单':'Delete order',
    '查看物流':'View logistics',
    '评价':'Evaluation',
    '退款详情':'Refund details',
    '待付款':'To be paid',
    '等待退款':'Waiting for refund',
    '其他':'Others',
    '订单ID':'Order ID: ',
    '买家名称':'Buyer name: ',
    '共件':'Total  items',
    '前往发货':'Go to ship',
    '联系买家':'Contact buyer',
    '金额':', amount ',
    '退款':'Refund',
    '已发货':'Shipped',
    '已收货':'Received',
    '已完成':'Completed',
    '已关闭':'Closed',
    '退款订单':'Refund order',
    '订单已发货':'Order shipped',
    '订单已签字':'Order signed',
    '订单完成':'Order Completed',
    '订单已关闭':'Order closed',
    '全部订单':'All orders',
    '填写物流订单号':'Fill in the logistics order number',
    '选择物流公司':'Choose a logistics company',
    '确认发货':'Confirm shipment',
    '复制地址':'Copy address',
    '运费':'Freight',
    '需付款/实付款':'Payment required/Actual payment',
    '订单ID':'Order ID: ',
    '订单详情':'Order details',
    '符合描述':'Matches description',
    '发布':'Release',
    '留下评论':'Leave a review',
    '宝贝符合你的期望吗？说说它的优点和缺点吧~':'Does the baby meet your expectations? Let’s talk about its advantages and disadvantages~',
    '用了一段时间，有没有更多的使用心得？分享给想购买的人':'Have been using it for a while, do you have more experience using it? Share it with those who want to buy it',
    '公开':'Public',
    '店铺评价':'Store evaluation',
    '送货服务':'Delivery service',
    '服务态度':'Service attitude',
    '添加图片':'Add picture',
    '添加视频':'Add video',
    '匿名':'Anonymous',
    '评论内容不能为空':'Comment content cannot be empty',
    '跟进评论':'Follow-up comment',
    '添加评价':'Add evaluation',
    '联系客服':'Contact customer service',
    '拨号':'Dial number',
    '复制':'Copy',
    '下单时间':'Order time: ',
    '支付方式':'Payment method: ',
    '支付时间':'Payment time: ',
    '确认收货':'Confirm receipt',
    '退款方式':'Refund method',
    '只支持退款':'Only refund',
    '退货及退款':'Return and refund',
    '退款原因':'Reason for refund',
    '退款金额':'Refund amount',
    '请选择':'Please select',
    '请填写10字以上的问题描述，以便我们提供更好的帮助':'Please fill in a problem description of more than 10 words so that we can provide better help',
    '提交申请':'Submit application',
    '退款申请':'Refund application',
    '请选择退款原因':'Please select the reason for refund',
    '取消':'Cancel',
    '重新申请':'Re-apply',
    '平台':'Platform',
    '协商历史':'Negotiation history',
    '退款信息':'Refund information',
    '退款方式':'Refund method:',
    '退款金额':'Refund amount:',
    '退款原因':'Reason for refund:',
    '申请时间':'Application time:',
    '退款订单ID':'Refund order ID:',
    '问题描述':'Problem description:',
    '拒绝原因':'Rejection reason:',
    '拒绝描述':'Rejection description:',
    '取消退款申请后，该订单无法再次申请退款，是否确定取消？':'After canceling the refund application, the order cannot apply for refund again. Are you sure to cancel? ',
    '同意':'Agree',
    '订单状态':'Order status:',
    '买家昵称':'Buyer nickname:',
    '选择拒绝前，请尽量与买家充分沟通':'Please try to fully communicate with the buyer before choosing to reject',
    '拒绝退款':'Refuse to refund',
    '拒绝原因':'Denial Reason',
    '请填写详细原因及证据描述，以帮助快速解决您的问题':'Please fill in the detailed reasons and evidence description to help quickly solve your problem',
    '请选择拒绝原因':'Please select the reason for rejection',
    '继续':'Continue',
    '平台介入':'Platform intervention',
    '申诉原因':'Reason for appeal',
    '申诉原因':'Reason for appeal',
    '查看更多评论':'View more reviews',
    '带图片':'With pictures',
    '追加':'Append',
    '全部评论':'All reviews',
    '账户余额（$）':'Account balance ($)',
    '退款记录':'Refund record',
    '提现余额':'Withdraw balance',
    '无相关退款记录':'No related refund record',
    '账单管理':'Bill management',
    '累计收益':'Cumulative income',
    '结算记录':'Settlement record',
    '店铺余额':'Store balance',
    '提取收益':'Extract income',
    '交易中':'In transaction',
    '无相关结算记录':'No relevant settlement record',
    '可提取':'Extractable',
    '输入提现金额':'Enter withdrawal amount',
    '请输入提现金额':'Please enter the withdrawal amount',
    '预览不支持该功能':'Preview does not support this feature',
    '申请':'Apply',
    '我购买了':'I purchased',
    '申请说明':'Application instructions',
    '我已阅读并同意':'I have read and agreed to LUCKY LIVE\'s',
    '立即申请':'Apply now',
    '我看':'I See',
    '我上传':'I uploaded',
    '上传付费内容':'Upload paid content',
    '内容标题':'Content title',
    '建议填写关键词、属性词、营销词等，不超过15个字':'It is recommended to fill in keywords, attribute words, marketing words, etc., no more than 15 words',
    '内容封面':'Content cover',
    '封面图片':'Cover image',
    '内容介绍':'Content introduction',
    '建议通过视频内容、故事情节、学习技巧等阐述内容特色，不超过100字':'It is recommended to explain the content features through video content, storyline, learning skills, etc., no more than 100 words',
    '个人介绍':'Personal introduction',
    '建议填写作者身份、成果、过往作品等，不超过50字':'It is recommended to fill in the author’s identity, achievements, past works, etc., no more than 50 words',
    '内容价格':'Content price',
    '上传视频':'Upload video',
    '确认收货':'Confirm receipt',
    '买家留言':'Buyer\'s message',
    '内容分类':'Content category',
    '单视频':'Single video',
    '多视频':'Multiple videos',
    '字幕':'Subtitle',
    '最多15个字符':'Maximum 15 characters',
    '添加视频':'Add video',
    '预览':'Preview',
    '请选择视频':'Please select a video',
    '请设置视频标题':'Please set the video title',
    '请选择分类':'Please select a category',
    '请输入内容标题':'Please enter the content title',
    '请输入内容介绍':'Please enter the content introduction',
    '请输入个人介绍':'Please enter personal introduction',
    '请输入价格':'Please enter the price',
    '请选择封面图':'Please select a cover image',
    '人已购买':'people have purchased',
    '拒绝':'Rejected',
    '视频详情':'Video details',
    '视频介绍':'Video introduction',
    '观众评论':'Audience comments',
    '精选':'Selections',
    '作者信息':'Author information',
    '时长':'Duration: ',
    '播放':'Play',
    '人已评分':'people have rated',
    '评价视频':'Evaluate video',
    '观看视频':'Watch video',
    '商店':'Shop',
    '付费内容':'Paid',
    '您尚未购买付费内容':'You have not purchased paid content',
    '您尚未发布付费内容':'You have not published paid content',
    '查看付费内容':'View paid content',
    '购买付费内容':'Purchase paid content',
    '非常不满意':'Extremely dissatisfied',
    '不满意':'Not satisfied',
    '满意':'Satisfied',
    '非常满意':'Very satisfied',
    '非常满意':'Very satisfied',
    '订单留言':'Order message',
    '点击查看':'Click to view',
    '支付失败':'Payment failed',
    '（买方）':'(Buyer side)',
    '您确定要删除订单吗？':'Are you sure you want to delete the order? ',
    '您确定要取消订单吗？':'Are you sure you want to cancel the order? ',
    '您确定已收到商品吗？':'Are you sure you have received the product? ',
    '该商品已下架':'This product has been removed from the shelves',
    '再考虑一下':'Think again',
    '本站商品':'Site goods',
    '场外商品':'Off-site goods',
    '场外':'off-sit',
    '该商品为场外链接商品，可能存在一定风险，请谨慎购买':'This product is an off-site linked product and may involve certain risks. Please purchase with caution',
    '继续购买':'Continue to buy',
    '商品链接不存在':'Product link does not exist',
    '已拒绝':'Rejected',
    '确认删除？':'Confirm deletion?',
    '确定要下架商品？':'Are you sure you want to remove the product? ',
    '请输入付费内容名称':'Please enter the paid content name',
    '确认上架商品？':'Confirm products to be put on shelves? ',
    '确认删除商品？':'Confirm to delete product? ',
    '请设置收货人信息':'Please set the consignee information',
    '请同意条款':'Please agree to the terms',
    '分钟':'Minute',
    '退出':'Logout',
    '确认退出':'Confirm logout',
    '点击确认退出后，账号将立即注销。':'After clicking to confirm the cancellation, the account will be canceled immediately.',
    '*短信验证保证账号安全，短信费用由平台支付':'* SMS verification ensures account security and the SMS fee will be paid by the platform',
    '满足以下条件，当前账号才能注销':'Only when the following conditions are met can the current account be canceled',
    '注销条件': 'Conditions for account deletion',
    '已通过':'Passed',
    '未通过':'Failed',
    '请输入商品名称':'Please enter product name',
    '暂无商品':'No product yet',
    '新品':'New product',
    '销售中':'Sales',
    '价格':'Price',
    '去买':'Go and buy',
    '收藏':'Collect',
    '我的收藏':'My collection',
    '搜索话题':'Search topics',
    '话题':'Topic',
    '全部话题':'All topics',
    '选择话题':'Select topic',
    '参与话题':'Participate in the topic',
    '推荐话题':'Recommended topics',
    '搜索话题':'Search topics',
    '热门话题':'Hot topic',
    '查看更多':'View more',
    '每日任务':'Daily task',
    '未完成':'Undone',
    '领取':'Receive',
    '已领取':'Received',
    '抽礼物进入涂鸦模式':'Draw gifts and enter graffiti mode',
    '抽至少10份礼物':'Draw at least 10 gifts',
    '涂鸦':'Graffiti',
    '撤回':'Withdraw',
    '您已撤回一条消息':'You have withdrawn a message',
    '对方撤回了一条消息':'The other party withdrew a message',
    '该消息发送已超过两分钟，无法撤回':'This message has been sent for more than two minutes and cannot be withdrawn',
    '撤回失败':'Withdraw failed',
    '保存到本地相册':'Save to local album',
    '保存成功':'Save successfully',
    '确定退出？':'Are you sure you want to log out?',
    '退出':'Log out',
    '业务分类':'Business category',
    '管理员正在快速审核~':'The administrator is undergoing rapid review~',
    '审核失败，请重新提交':'Review failed, please resubmit',
    '重新提交':'resubmit',
    '业务分类设置':'Business category settings',
    '请慎重选择，主分类设置成功后不可更改':'Please choose carefully, the main category cannot be changed after it is successfully set',
    '选择主分类':'Select the main category',
    '暂无收藏':'No favorites yet',
    '该商品已下架':'The product has been removed from the shelves',
    '我的商品':'My products',
    '平台':'Platform',
    '平台商品':'Platform goods',
    '平台暂无商品':'There are currently no platform products',
    '奖励':'Reward',
    '代销':'Consignment',
    '取消代销':'Cancel sales agency',
    '确认取消销售该商品？':'Confirm to cancel the sale of this product? ',
    '请至个人中心-联系我们 联系平台':'Please go to Personal Center-Contact Us Contact Platform',
    '积分奖励设置':'Share reward settings',
    '佣金（元）':'Commission ($)',
    '聊天室':'Chat room',
    '幸运奖池':'Lucky Prize Pool',
    '抽奖':'Lucky Draw',
    '手机号所在地':'Mobile phone number location',
    '请输入地区名称':'Please enter the region name',
    '以下信息为必填项，为保证您的利益，请如实填写':'The following information is required. To ensure your interests, please fill it in truthfully',
    '真实姓名':'Real name',
    '请填写您的真实姓名':'Please fill in your real name',
    '手机':'Mobile',
    '请填写您的手机号':'Please fill in your phone number',
    '证件号':'ID number',
    '请填写您的证件号':'Please fill in your ID number',
    '证件正面':'Front of ID',
    '证件反面':'Back side of ID',
    '手持正视图（手持证件照）':'Handheld front view  (Holding ID photo) ',
    '请手持证件并按照图示拍照\n请确保证件信息清晰可见':'Please hold the ID and take a photo as shown\nMake sure the ID information is clearly visible',
    '提交认证':'Submit certification',
    '申请认证':'Apply for certification',
    '余额不足，请先充值':'Insufficient balance, please recharge first',
    '立即充值':'Recharge now',
    '家庭申请':'Family application',
    '家族名称':'Family name',
    '请输入家族名称':'Please enter family name',
    '个人姓名':'Personal name',
    '请输入您的姓名':'Please enter your name',
    '佣金比例':'Rake ratio',
    '请填写0-100之间的整数':'Please fill in an integer between 0-100',
    '家庭简介':'Family Profile',
    '请介绍您的家庭':'Please introduce your family',
    '证件照':'ID photo',
    '家庭照':'Family pictures',
    '手持后视':'Hand-held rear view',
    '请输入佣金比例':'Please enter the commission ratio',
    '请输入家庭简介':'Please enter family profile',
    '提交申请':'Submit application',
    '等待中':'Waiting',
    '聊天室':'Chatroom',
    '互动':'Interactive',
    '申请':'Apply',
    '取消排队':'Cancel queue',
    '您当前的订单（）':'Your current order ()',
    '当前申请（）':'Current application ()',
    '同意':'Agree',
    '麦子成功':'Wheat successfully',
    '麦子被拒绝':'Wheat was rejected',
    '控制麦子':'Control wheat',
    '退出':'Exit',
    '关麦':'Close mic',
    '禁言':'Ban',
    '取消':'Cancel',
    '开麦':'Open mic',
    '您已被掉线':'You have been dropped',
    '已设置为静音':'Already set to mute',
    '取消静音':'Unmuted',
    '退出直播室':'Exit the live room',
    '聊天室最小化':'Chat room minimized',
    '直播室开启时无法使用该操作':'This operation cannot be used while the live room is open',
    '语音直播':'Voice live',
    '请完善证件信息':'Please complete the certificate information',
    '请选择发送对象':'Please choose the person to send it to',
    '美颜支付成功':'Beauty Payment successful',
    '美颜支付失败':'Beauty Payment failed',
    '取消支付':'Cancel payment',
    '创建家庭':'Create a family',
    '基本信息':'Basic information',
    '上传身份信息':'Upload identity information',
    '点击跳转第三方应用':'Click to jump to third-party applications',
    '您还未登录':'You are not logged in yet',
    '登入账号查看精彩内容':'Log in to your account to view exciting content',
    '分享':'Share',
    '分享赚取佣金':'Sharing earns commission ',
    '分享到新闻':'Share to news',
    '分享给好友':'Share to friends',
    '分享':'Sharing',
    '分享佣金':'Sharing Commission',
    '立即充值':'Recharge now',
    '首冲豪华礼包':'First flush luxury gift package',
    '广告':'Advertising',
    '请选择支付方式':'Please select payment method',
    '作者':'Author',
    '关注者购买了元':'The follower bought  $',
    '分享商品':'Share products',
    '您确定要删除所选收藏吗？':'Are you sure you want to delete the selected collection? ',
    '请您务必仔细阅读、充分理解服务协议及隐私政策中的各项条款，包括但不限于为了给您提供即时通讯、内容分享等服务，我们需要收集您的设备信息及个人信息，您可以在设置中查看和管理您的授权。您可以阅读《隐私政策》及《服务协议》了解详细信息，如您同意，请点击同意接受我们的服务。':'Please be sure to read it carefully and fully understand the terms of the service agreement and privacy policy, including but not limited to, in order to provide you with instant messaging, content sharing and other services, we need to collect your device information and personal information. You can view and manage your authorization in settings. You can read the "Privacy Policy" and "Service Agreement" for detailed information. If you agree, please click to agree to accept our services. ',
    '登录即表示您同意':'Login means you agree to the',
    '《隐私政策》':'《Privacy Policy》',
    '及':'and ',
    '和':'and ',
    '《服务协议》':'《Service Agreement》',
    '服务协议和隐私政策':'Service Agreement and Privacy Policy',
    '感谢您的支持，为了更好地维护您的权益，请阅读并同意':'Thank you for your support. In order to better protect your rights, please read and agree',
    '立即登录':'LOGIN NOW',
    '请选择':'請選擇',
    '\@好友':'\@Friends',
    '您已经@了他':'You have already @ him',
    '已经@了同昵称的人':'Already @people with the same nickname',
    '粉丝':'Fans',
    '点赞':'Like',
    '\@我':'\@Me',
    '评论':'Comments',
    '人点赞了您的作品':'liked your work',
    '充值奖励':'Recharge rewards',
    '直播详情':'Live details',
    '查看详情':'View details',
    '尚未开通聊天室~':'No chat room has been opened yet~',
    '人评论了您的作品':'commented on your work',
    '人在内@了您':'@ you in ',
    '人关注了您':'followed you',
    '人点赞了您的评论':'liked your comment',
    '人回复了您的评论':'replied to your comment',
    '清空':'Clear',
    '首次充值机会已使用完毕':'The first recharge opportunity has been used up',
    '确定要清空所有中奖记录吗？':'Are you sure you want to clear all winning records? ',
    '请选择封禁时间':'Please select the ban time',
    '我知道了':'I see',
    '人正在直播':'people are live streaming',
    '全部分类':'All categories',
    '直播购买':'Live purchase',
    '官方直销·正品保障':'Official direct sales · Genuine guarantee',
    '认证':'Certification',
    '去认证':'Go for certification',
    '我的服务':'My services',
    '更多服务':'More services',
    '会员续费':'Renew membership',
    '有效期':'Expiration time: ',
    '会员专享大礼包来袭':'Member-exclusive gift pack is here',
    '点赞':'Likes',
    '更换':'Replace',
    '去关闭':'Go to close',
    '修改密码':'Change password',
    '青少年模式':'Teen mode',
    '青少年模式未开启':'Youth mode is not enabled',
    '青少年模式已开启':'Youth mode is on',
    '开启青少年模式需先设置独立密码':'To enable youth mode, you need to set an independent password first',
    '无法进行充值、打赏等操作':'Cannot perform recharge, reward and other operations',
    '自动开启时间锁，每天使用时长不得超过40分钟，且每天晚上22点至次日下午6点之间不能使用':'Automatically turn on the time lock, the usage time shall not exceed 40 minutes per day, and it cannot be used between 22:00 pm every day and 6:00 pm the next day',
    '开启青少年模式':'Turn on teen mode',
    '关闭青少年模式':'Turn off teen mode',
    '设置密码':'Set password',
    '请设置新密码':'Please set a new password',
    '输入密码':'Enter password',
    '密码设置成功，请记住您设置的密码':'Password set successfully, please remember the password you set',
    '请输入当前密码':'Please enter the current password',
    '请输入新密码':'Please enter a new password',
    '请确认新密码':'Please confirm the new password',
    '进入青少年模式':'Enter teen mode',
    '青少年模式下暂无商品信息':'No product information in youth mode',
    '青少年模式下不支持该功能':'This function is not supported in youth mode',
    '未成年人不得使用':'Not for use by minors',
    '重置':'Reset',
    '确认修改':'Confirm modification',
    '高德地图':'Gaode map',
    '百度地图':'Baidu map',
    '腾讯地图':'Tencent Map',
    '您没有安装导航软件':'You do not have navigation software installed',
    '请开启通知':'Please turn on notification',
    '私信已关闭暂时无法使用':'Private messaging has been closed and is temporarily unavailable',
    '直播类型':'Live type:',
    '语音聊天室':'Voice chat room',
    '视频聊天室':'Video chat room',
    '首充礼包':'First Charge Gift Pack',
    '1元礼包':'1 $ gift package',
    '10元礼包':'10 $ gift package',
    '100元礼包':'100 $ gift package',
    '首充豪华礼包，快来充值领奖励':'First charge luxury gift package, come and recharge to receive rewards',
    '充值后送钻石':'After recharging, give away diamonds ',
    '充值后送钻石和礼物':'After recharging, give away diamonds and gifts',
    '充值后送钻石和VIP':'After recharging, give away diamonds and VIP',
    '贴纸':'Sticker',
    '美颜':'Beauty',
    '特效':'Special effects',
    '勾镜':'Hooking mirror',
    '基础贴纸':'Basic stickers',
    '高级贴纸':'Advanced Stickers',
    '基础面膜':'Basic mask',
    '高级面膜':'Advanced Mask',
    '贴纸下载失败':'Sticker download failed',
    '美颜':'Beauty',
    '美颜':'Beauty',
    '一键美颜':'One-click beauty',
    '滤镜':'Filter',
    '水印':'Watermark',
    '动作':'Action',
    '美颜':'Beauty',
    '原图':'Original image',
    '美白':'Whitening',
    '磨皮':'Dermabrasion',
    '红润':'Ruddy',
    '亮度':'Brightness',
    '大眼':'Big eyes',
    '眉毛':'Eyebrows',
    '眼距':'Eye distance',
    '眼角':'Eye corners',
    '瘦脸':'Face slimming',
    '嘴型':'Mouth shape',
    '瘦鼻':'Shoubi',
    '下巴':'Chin',
    '额头':'Forehead',
    '长鼻':'Long nose',
    '修脸':'Face-shaping',
    '开眼角':'Open your eyes',
    '标准':'Standard',
    '优雅':'Elegance',
    '精致':'Exquisite',
    '可爱':'Cute',
    '自然':'Natural',
    '网红':'Internet celebrity',
    '精致':'Refined',
    '典雅':'Elegance',
    '无':'None',
    '浪漫':'Romantic',
    '清新':'Fresh',
    '唯美':'Aesthetic',
    '粉色':'Pink',
    '怀旧':'Nostalgia',
    '酷炫':'Cool',
    '蓝调':'Blues',
    '日系':'Japanese',
    '都市':'City',
    '初恋':'First love',
    '初心':'Original intention',
    '单色':'Single color',
    '撞色':'Contrast color',
    '琥珀色':'Amber',
    '美味':'Delicious',
    '蜜桃粉':'Peach powder',
    '奶茶':'Milk tea',
    '宝丽来':'Polaroid',
    '乌托邦':'Utopia',
    '柚子':'Grapefruit',
    '每日杂项':'Daily Miscellaneous',
    '黑猫':'Black Cat',
    '黑白':'Black and white',
    '布鲁克林':'Brooklyn',
    '平静':'Calm',
    '冷酷':'Cold',
    '凯文':'Kevin',
    '爱情':'Love',
    '呈现':'Presentation',
    '灵魂出窍':'Out-of-body experience',
    '抖动':'Jitter',
    '闪白':'Flash white',
    '故障':'Glitch',
    '幻觉':'Hallucination',
    '马赛克1':'Mosaic 1',
    '马赛克2':'Mosaic 2',
    '马赛克3':'Mosaic 3',
    '马赛克4':'Mosaic 4',
    '原图':'Original image',
    '抬头':'Look up',
    '张开嘴':'Open your mouth',
    '眨眼':'Blink',
    '外星人':'Alien',
    '梨子脸':'Pear face',
    '瘦脸':'Slim face',
    '镜面脸':'Mirror face',
    '碎片脸':'Fragment face',
    '水中倒影':'Water reflection',
    '螺旋镜':'Spiral mirror',
    '鱼眼相机':'Fisheye camera',
    '左右':'Left and right',
    '原图':'Original image',
    '睫毛':'eyelashes',
    '唇彩':'Lip gloss',
    '腮红':'Blush',
    '眼影':'Eye shadow',
    '眼线':'Eyeliner',
    '':'',
    '请抬头':'Please look up',
    '请张开嘴':'Please open your mouth',
    '请眨眼':'Please blink',
    '贴纸正在下载，请等待':'Stickers are being downloaded, please wait',
    '三张扑克':'Three card poker',
    '命运之轮':'Wheel of Fortune',
    '选择游戏':'Select game',
    '即将开始，请等待':'Starting soon, please wait',
    '获胜':'Won',
    '请等待游戏结束':'Please wait for the game to end',
    'W':'W',
    '大乔x2':'DaQiaox2',
    '貂蝉x2':'DiaoChanx2',
    '小乔x2':'XiaoQiaox2',
    '开始应援':'Start supporting',
    '揭晓结果':'Reveal the result',
    '胜负为标的':'Victory or defeat is standard issue',
    '我点亮了':'I light up',
    '被踢出房间':'was kicked out of the room',
    '被永久禁言':'is permanently banned',
    '被本场禁言':'has been banned from this site',
    '被设为管理员':'is set as administrator',
    '被取消管理员':'was removed as administrator',
    '在直播间发红包啦快去抢哦':'give out red envelopes in the live broadcast room! Go and grab it~',
 
    '欢迎来到直播间':'Welcome to the live broadcast room, we advocate green live broadcasting. Accounts with illegal live content and covers, pornography, smoking and drinking, inducing fraud, gathering crowds to make trouble, etc. will be banned, and the Internet police will patrol online 24 hours a day!',
    '主播离开一下':'The anchor leaves for a while, the excitement will not be interrupted, don\'t go away',
    '主播回来了':'The host is back!',
    '关注了主播':'followed the anchor',
 
 
    '收藏':'Collection',
    '热门':'Popular',
    '社区':'Community',
    '游戏':'Game',
    '视频':'Video',
    '终极会员':'Ultimate Member',
    '启用':'ACTIVATE',
    '充值':'Recharge',
    '钱包':'Wallet',
    '我的钱包':'My wallet',
    '每日任务':'Daily Quest',
    '因汇率波动充值金额可能会存在变化 请以支付时金额为准':'Due to exchange rate fluctuations, the recharge amount may change. Please refer to the amount at the time of payment',
    '提现':'Withdraw cash',
    '奖励':'Reward',
    '收入':'Income',
    '看视频20秒得现金打款':'Watch the video for 20 seconds and get cash',
    '个人中心':'Personal Center',
    '消息':'Message',
 
 
    '欢迎登录':'Nice to meet you !',
    '我的现金':'My Money',
    '红包已经达到今日上限':'The red envelope has reached today\'s limit',
 
    '总数':'Total',
    '首充礼包':'First deposit gift package',
    '快来充值领奖励吧':'Come and recharge to get rewards',
    '请联系管理员充值':'Please contact the administrator to recharge',
 
    '现金':'cash:',
    '功能暂未上线':'The function is not yet online, please stay tuned',
 
    '电话区号':'+84',
 
 
    '倍速':'Rate',
    '两倍快进中':'2x fast forward',
    '切换倍速提示':'Switched to {}x speed playback',
    '验证码':'Verification code',
    '同意并登录':'Agree and log in',
 
    '直播中':'Live streaming',
 
    '确定取消关注':'Confirm to unfollow  ?',
    '取消关注':'Unfollow',
    '继续关注':'Continue to follow',
 
     // 首次启动引导
    '领取':'CLAIM',
    '欢迎来到LuckyLive':'Welcome to Lucky Live',
    '你可以在这里发表视频':'You can post videos here',
    '看视频可以获得金币':'Gold coin reward for watching videos',
    '您可以在这里开始直播':'You can start the live broadcast here',
    '您可以在这里提取现金':'You can withdraw cash here',
 
    '知道了':'I got it',
 
     // 新直播UI
    '普通':'Normal',
    '热门标签':'HOT',
    '幸运标签':'LUCKY',
    '守护标签':'Guard',
    '钻石余额不足充值后才可继续赠送礼物':'The diamond balance is insufficient. You can continue to send gifts after recharging.',
    '去充值':'Go to recharge',
 
    '新消息':'New message',
    '刚刚':'a moment ago',
    '分钟前':'minute ago',
    '昨天':'Yesterday',
 
    '周一':'Monday',
    '周二':'Tuesday',
    '周三':'Wednesday',
    '周四':'Thursday',
    '周五':'Friday',
    '周六':'Saturday',
    '周日':'Sunday',
 
    '上周一':'Last Monday',
    '上周二':'Last Tuesday',
    '上周三':'Last Wednesday',
    '上周四':'Last Thursday',
    '上周五':'Last Friday',
    '上周六':'Last Saturday',
    '上周日':'Last Sunday',
    '钻石':'Diamond',
 
    '支付':'Payment',
    '支付方式':'Payment method',
    '选择支付方式':'Select Payment Method',
 
    '订单号':'Order Number:',
    '已支付':'Paid',
 
    '订单请求中':'Order requesting, please wait...',
    '订单确认中':'Order confirmation, please wait...',
    '返回':'Back',
    '支付成功':'Successful payment',
    '支付失败':'Payment failed',
    '排行榜':'Ranking',
    '收益榜':'Revenue list',
    '贡献榜':'Contribution list',
 
    '下载APP':'Download Lucky Live App',
    '请下载Lucky Live APP':'Please download the Lucky Live App',
    '条':'',
    '私信给':'Send a private message to',
    '发送':'send',
    'token已过期':'Your login status has expired, please log in again',
    '选择直播内容':'Select live content',
    '购物车':'Shopping cart',
    '在线观众':'Online viewers',
    '开启定位服务':'Turn on location services',
    '开启定位提示':'In order to recommend nearby live broadcasts and videos to you, help you display your positioning when using related services, enable positioning, and discover the life around you',
    '设置':'Set up',
    '人在看':'Viewers',
    '表情':'Emoji',
    '进入全功能模式':'Enter full function mode',
    '不同意':'Disagree',
    '同意':'Agree',
    '不同意并进入基本功能模式':'Disagree and enter basic functionality mode',
    '什么是基本功能模式':'What is basic functional mode',
    '星球探宝':'Treasure hunt',
    '幸运大转盘':'Lucky turn',
    '冥王星':'Pluto',
    '天王星':'Uranus',
    '海王星':'Neptune',
    '规则说明':'Rule description',
    '中奖记录':'Winning record',
    '暂无抽奖记录':'No winning record yet',
    '您还没有中奖记录':'You have no winning record yet',
    '暂无抽奖排行榜':'No lottery rankings yet',
    '恭喜获得':'Congratulations on getting',
    '未获得':'Not got',
    '未获得提示':'Unfortunately\nYou missed the grand prize',
    '获得礼物提示':'Gifts obtained are stored in the backpack',
    '排名':'Rank',
    '用户信息':'User',
    '奖品累计价值':'Total value',
    '个':'',
    '玩法介绍':'Game rules',
    '参与记录':'Game records',
    '跳过动画':'Skip animation',
    '单击':'click',
    '十连击':'10 hits combo',
    '百连击':'100 hits combo',
    '手游直播':'Game live',
    '开始录屏':'Start recording screen',
    '用户连麦':'Link mic',
    '主持':'Host',
    '全麦':'All',
    '一麦':'No.1',
    '二麦':'No.2',
    '三麦':'No.3',
    '四麦':'No.4',
    '五麦':'No.5',
    '六麦':'No.6',
    '七麦':'No.7',
    '八麦':'No.8',
    '复制':'Copy',
    '发布成功':'Published successfully',
    '上传成功':'Upload successful, please wait for review',
    '上传失败':'Upload failed',
    '请上传视频':'Please upload a video',
    '退出':'Sign out',
    '语言切换':'Language switching',
    '粉丝':'Fans',
    '关注':'Follow',

    '钻石记录':'Diamond Record',
    '金币记录':'Gold coin record',
    '赠送礼物':'Giving gifts',
    '收到礼物':'Received a gift',
    '游戏结算':'Game settlement',
    '后台充值':'Backend recharge',
    '提款费':'Withdrawal fee',
    '手续费':'Processing fee',
    '暂无数据':'No data',
    '提现钻石数':'Withdrawal of diamonds',
    '可转化为':'Can be converted into',
    '提款':'Withdraw money',
    '单次提现范围':'The withdrawal amount each time is ',
    '钻石每天可提现':'Diamonds can be withdrawn daily',
    '次':'number',
    '提款规则':'Withdrawal Rules',
    '进行中':'In progress',
    '成功':'Successful',
    '账户': 'account',
    '查看原因':'Check the reason',
    '提款方式':'Withdrawal method',
    '选择到账银行卡': 'Select the bank card for receiving funds',
    '请注意各银行到账时间': 'Please note the arrival time of each bank account',
    '使用新卡提现': 'Withdraw using a new card',
    '编辑资料':'Edit information',
    '男':'Man',
    '女':'Woman',
    '点击设置':'Click on settings',
    '修改昵称':'Change nickname',
    '保存':'Save',
    '修改签名':'Modify signature',
    '记得填写名字哦':'Remember to fill in your name',
    '这家伙很懒，什么都没留下':'This guy is very lazy, he didn\'t leave anything behind',
    '是否保存修改':'Do you want to save the modifications',
    '名字不能为空':'Name cannot be empty',
    '是否退出登录':'Do you want to log out',
    '退出登录':'Log out',
    '很高兴认识你':'Nice to meet you！',
    '英文':'English',
    '越南语':'Vietnamese',
    '繁体中文':'traditional Chinese',

    '总金额':'Total Amoun',
    '全部':'whole',
    '可提现钻石':'Withdrawable diamonds',
    '原生APP': 'Native APP',
    '桌面快捷APP': 'Desktop Shortcut APP',


    '公会管理': 'Guild management',
    '公会编号': 'Guild Number',
    '公会主播': 'Guild broadcaster',
    '详细': 'detailed',
    '旗下主播': 'Under the banner of anchor',
    '旗下主播收益': 'Revenue of its broadcasters',
    '当月活跃主播': 'Active anchor of the month',
    '公会分成收益': 'Guild revenue sharing',
    '本月分成比例': 'Sharing ratio for this month',
    '本月总收益': 'Total revenue for this month',
    '公会主播收益': 'Guild anchor',
    '当月收益': 'Monthly income',
    '昨日收益': 'Yesterday\'s earnings',
    '平台通知': 'Platform Notification',
    '违规记录': 'Violation record',
    '分成比例': 'Sharing ratio',
    '钻石分成比例': 'Diamond sharing ratio',
    '金币分成比例': 'Gold coin sharing ratio',
    '钻石礼物分成比例': 'Diamond gift sharing ratio',
    '金币礼物分成比例': 'Gold coin gift sharing ratio',
    '请输入分成比例': 'Please enter the sharing ratio',
    '总收益': 'Total revenue',
    '场次': 'Session',
    '钻石礼物': 'Diamond gift',
    '金币礼物': 'Gold coin gift',
    '其他': 'Other',
    '主播分成': 'Anchor sharing',
    '结算金额': 'Settlement amount',
    '累计收益': 'Accumulated income',
    '钻石收益': 'Diamond income',
    '直播时长': 'Livestream duration',
    '有效直播天数': 'Effective live streaming days',
    '导出': 'export',
    '筛选': 'screen',
    '选择筛选方式': 'Select filtering method',
    '主播': 'anchor',
    '昵称或id号': 'Nickname or ID number',
    '主播等级': 'Anchor level',
    '时间范围': 'time frame',
    '取消': 'cancel',
    '确定': 'determine',
    '导出成功': 'Export successful',
    '在浏览器中打开链接，即可下载': 'Open the link in the browser to download',
    '复制链接': 'Copy link',
    '主播详情': 'Anchor Details',
    '即：用户赠送价值100钻石的礼物，主播可获得100*50%=50钻石': 'Namely, if the user gives a gift worth 100 diamonds, the anchor can receive 50 diamonds equal to 100 * 50%',
    '即：用户赠送价值1000金币的礼物，主播可获得1000*50%=500金币，关播后统一兑换为钻石': 'Namely: Users can give a gift worth 1000 gold coins, and the anchor can receive 1000 * 50%=500 gold coins, which will be exchanged for diamonds after the broadcast is turned off',
    '个人信息': 'personal information',
    '姓名': 'full name',
    '国家/地区': 'Country/Region',
    '城市': 'city',
    '手机号': 'cell-phone',
    '直播信息': 'Live streaming information',
    '累计直播时长': 'Accumulated live streaming duration',
    '签约信息': 'subscription information',
    '签约时间': 'Signing time',
    '开播时间': 'Start time',
    '查看直播记录': 'View live streaming records',
    '点击查看': 'Click to view',
    '警告': 'warning',
    '封禁7天': 'Banned for 7 days',
    '登录用户不一致，请重新登录':'Login users are inconsistent, please login again',
    
    '任务中心': 'Task Center',
    '新增一个视频': 'Create a video',
    '观看视频': 'Watch videos',
    '轻松赚金币，快速提现': 'Easily earn coins and withdraw quickly',
    '开播10分钟': 'Open the livestream for 10 minutes',
    '观看直播10分钟': 'Watch the livestream for 10 minutes',
    '关注主播': 'Follow the room owner',
    '送1份礼物': 'Give a gift',
    '发布1条动态': 'Publish 1 dynamic',
    '每日0点重置，所有时间均以UTC+07:00为准':'Every day at 12:00 pm will refresh, with all locations using UTC+07:00 as the standard time',
    '本活动与苹果公司无关':'This event is not related to Apple Inc',
    '观看视频，快速提现':'Watch videos and quickly withdraw funds',

    '直播收益': 'Livestream revenue',
    '公会管理': 'Guild management',
    '今日开播时长（仅统计大于10min的场次）': 'Livestream duration for today (only counting sessions over 10 minutes)',
    '场统计': 'Session statistics',
    '日统计': 'Daily statistics',
    '月统计': 'Monthly statistics',
    '日期': 'date',
    '时长': 'Duration',
    '开播时间':'Start time',
    '关播时间':'End time',
    '今日收益': 'Revenue for today',
    '本月收益': 'Revenue for this month',
    '本月开播时长': 'Livestream duration for this month',
    '在线天数': 'Online Days',
    '收益': 'Income',
    '上拉加载更多...': 'Pull up to load more',
    '公会管理后台': 'Guild management backend',
    // '今日已看满10分钟，明天再来':'I have watched for 10 minutes today, come back tomorrow',
    '今日已看满':'I have watched for ',
    '分钟，':'minutes',
    '明天再来':'come back tomorrow',
    '点击加载更多':'Click to load more',

    '最多可添加':'Can be added at most ',
    '个提现账号':' A withdrawal account',
    
    '场次：开播时间~关播时间':'Venue: Start time~End time',
    '钻石礼物：本场直播收到钻石礼物分成收益':'Diamond Gift: In this live broadcast, we receive diamond gifts and share the profits',
    '金币礼物：本场直播收到金币礼物分成收益':'Gold coin gift: In this live broadcast, we receive gold coin gifts and share the profits', 
    '其他：累计本场直播获得的游戏、门票或计时分成收益，总收益（钻石）=钻石礼物+金币礼物+其他':'Other: Accumulate game, ticket, or timed revenue from this live broadcast, total revenue (diamonds)=diamond gift+gold coin gift+other', 
    '注意：收到的金币在结束直播时统一兑换为钻石':'Attention: The received coins will be exchanged for diamonds at the end of the live broadcast',
    '关闭':'close',

    '充值方式':'Recharge method',
    '确认':'confirm',

    '订单确认中，请稍等...':'Order confirmation in progress, please wait...',
    '请重新登录':'Please login again',
    
    '请在APP发布视频':'Please post a video on the app',
    '请在APP开启直播':'Please enable live streaming on the app',
    
    '请绑定提款方式': 'Please bind the withdrawal method',
    '银行名称不能为空': 'Bank name cannot be empty',
    '账户名称不能为空': 'Account name cannot be empty',
    '验证码不能为空': 'Verification code cannot be empty',
    '请输入钻石数量': 'Vui lòng nhập số lượng kim cương',
    '提现失败': 'Withdrawal failed',
    '正在安装中...':'Installation in progress',

 }