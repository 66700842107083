
const router_modular = [
    // {
    //     path: '/',
    //     // path: '/allRankingList',
    //     name: 'homeView',
    //     component: () => import('@/views/homeView/homeView'),
    //     meta: {
    //         title: 'homeView',
    //     }
    // },
    {
        path: '/',
        // path: '/downloadApp',
        name: 'downloadApp',
        component: () => import('@/views/homeView/downloadApp'),
        meta: {
            title: 'downloadApp',
        }
    },
    // 直播收益(内嵌)
    // {
    //     path: '/',
    //     // path: '/liveStreamRevenue',
    //     name: 'liveStreamRevenue',
    //     component: () => import('@/views/mine/liveStreamRevenue'),
    //     meta: {
    //         title: 'liveStreamRevenue',
    //     }
    // },
    // 任务中心(内嵌)
    // {
    //     path: '/',
    //     // path: '/taskCenter',
    //     name: 'taskCenter',
    //     component: () => import('@/views/mine/taskCenter'),
    //     meta: {
    //         title: 'taskCenter',
    //     }
    // },
    // // 新排行榜(内嵌)
    // {
    //     path: '/',
    //     // path: '/allRankingList',
    //     name: 'allRankingList',
    //     component: () => import('@/views/allRankingList'),
    //     meta: {
    //         title: '排行榜',
    //     }
    // },
    // {
    //     path: '/rankingRule',
    //     name: 'rankingRule',
    //     component: () => import('@/views/allRankingList/rankingRule'),
    //     meta: {
    //         title: '排行榜规则',
    //         index:1,
    //     }
    // },
    // {
    //     path: '/',
    //     // path: '/newYearActivity',
    //     name: 'newYearActivity',
    //     component: () => import('@/views/newYearActivity'),
    //     meta: {
    //         title: '新春充值活动',
    //     }
    // },
    
    

]
export default router_modular