<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { setToken, getToken } from '@/utils/utils';
export default {
  name: 'app',
  data() {
    return {
      token: '',
    };
  },
  created(){
    //友盟APM性能统计
    const APM = require('@umengfe/apm');
    APM.init({
      // pageFilter: { mode: 'match', rules: [] },
      // pkgList: ['com.uemng.apmios', 'com.umeng.apmandroid'],
      pid:'63466e7f05844627b562e6ca',
    });
  },
  mounted(){
    //统一挂载到window让ios调用保存cookie中的token
    window.iosSetToken = this.iosSetToken;
    // if(!getToken()){
    //   // let token = window.webkit.messageHandlers.functionGetToken.postMessage()
    //   // this.iosSetToken(JSON.parse(token))
    //   let token = this.$route.params.token
      // setToken('X3CNEqtRFRQKLwcXTmGMOaOPHdjJCZoB')
    //   this.iosSetToken(token)
    // }
  },
  methods: {
    iosSetToken(value){
      console.log("进入methods，获取iosSetToken");
      if(value){
        console.log("value  ===== ", value);
        setToken(value)
        return true
      }else{
        return false
      }
    }
  },
  
};
</script>

<style lang="less">
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
fieldset, lengend, button, input, textarea, /* form elements 表单元素 */
th, td {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
  // background: #111111;
}
@font-face {
    font-family: DDINBold; 
    src: url('@/utils/D-DIN-Bold.otf');
}
</style>
